<nav class="mt-30 acm-portal-nav">
  <ng-container *ngFor="let item of navigationItems">
    <a class="pl-0 acm-portal-nav-item" #lvl1Nav="routerLinkActive" [routerLink]="'/portal/' + item.path"
      [routerLinkActive]="['is-active']">
      <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? item.navigation!.name : ''"
        matTooltipPosition="right">
        <mat-icon class="mi-18" [svgIcon]="item.navigation!.icon" *ngIf="item.navigation!.icon"></mat-icon>
        <i class="fig-18px {{item.navigation?.figIcon}}" *ngIf="item.navigation!.figIcon"></i>
      </span>
      <span class="acm-portal-nav-label" *ngIf="item.navigation">{{item.navigation.name}}</span>
    </a>
  </ng-container>
  <app-sub-navigation *ngIf="showSubNavigation" [subNavigationItems]="subNavigationItems"></app-sub-navigation>
  <div class="flex flex-col py-10 mt-auto border-t border-solid gap-y-10 border-gray-lighter dark:border-gray-dark">
    <ng-conainer *appShowIfOne="perm.ViewEvolvExperience">
      <a class="pl-0 acm-portal-nav-item" href="https://evolvfamilywealth.sharefile.com/home/shared/fo41eec4-84ca-4a26-8af6-62833595401d" target="_blank" rel="noopener" aria-label="Evolv ShareFile">
        <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Evolv ShareFile' : ''" matTooltipPosition="right">
          <img class="w-auto h-25 m-auto" src="assets/images/logos/evolv-logo-square.png">
        </span>
        <span class="acm-portal-nav-label">Evolv ShareFile</span>
      </a>
    </ng-conainer>
    <ng-conainer *appShowIfAll="perm.ViewOrionConnectSso">
        <ng-conainer>
        <a class="pl-0 acm-portal-nav-item" href="api/sso/orion/connect" target="_blank" rel="noopener" aria-label="Orion Advisor Solutions">
          <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Orion Advisor Solutions' : ''" matTooltipPosition="right">
            <img class="w-auto h-25 m-auto" src="assets/images/logos/navigation/orion-logo.png">
          </span>
          <span class="acm-portal-nav-label">Orion Advisor Solutions</span>
        </a>       
      </ng-conainer>
    </ng-conainer>
    <ng-conainer *appShowIfOne="perm.ViewOrionElementSso">     
      <a class="pl-0 acm-portal-nav-item" (click)="openOrionAgentSelectModal('ap_dashboard')" aria-label="Orion">
        <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Orion' : ''" matTooltipPosition="right">
          <img class="w-auto h-25 m-auto" src="assets/images/logos/navigation/orion-logo.png">
        </span>
        <span class="acm-portal-nav-label">Orion Advisor Portal</span>
      </a>
    </ng-conainer>   
    <ng-conainer *appShowIfAll="perm.ViewComplianceCentralSso">
      <a class="pl-0 acm-portal-nav-item" href="api/sso/basis-code" target="_blank" rel="noopener" aria-label="Orion Compliance">
        <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Orion Compliance' : ''" matTooltipPosition="right">
          <img class="w-auto h-25 m-auto" src="assets/images/logos/navigation/orion-logo.png">
        </span>
        <span class="acm-portal-nav-label">Orion Compliance</span>
      </a>
    </ng-conainer>
    <ng-conainer *appHideIfOne="perm.ViewSchwabAdvisorCenterSso">
      <a class="pl-0 acm-portal-nav-item" href="https://si2.schwabinstitutional.com/SI2/SecAdmin/Logon.aspx?to=%2fSI2%2fHome%2fDefault.aspx"
        target="_blank" rel="noopener" aria-label="Schwab Advisor Services Login">
        <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Schwab Advisor Services Login' : ''" matTooltipPosition="right">
          <img class="w-auto h-25 m-auto" src="assets/images/logos/navigation/schwab-logo.png">
        </span>
        <span class="acm-portal-nav-label">Schwab Advisor Services</span>
      </a>
    </ng-conainer>
    <ng-conainer *appShowIfAll="perm.ViewSchwabAdvisorCenterSso">
      <a class="pl-0 acm-portal-nav-item" href="api/sso/schwab?destination=D_DASH"
        target="_blank" rel="noopener" aria-label="Schwab Advisor Services">
        <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Schwab Advisor Services' : ''" matTooltipPosition="right">
          <img class="w-auto h-25 m-auto" src="assets/images/logos/navigation/schwab-logo.png">
        </span>
        <span class="acm-portal-nav-label">Schwab Advisor Services</span>
      </a>
    </ng-conainer>    
    <ng-conainer *appShowIfAll="perm.ViewLumaSso">
      <a class="pl-0 acm-portal-nav-item" (click)="openLumaAgentSelectModal()" aria-label="Luma">
        <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Luma' : ''" matTooltipPosition="right">
          <img class="w-auto h-25 m-auto" src="assets/images/logos/luma-logo-blue.svg">
        </span>
        <span class="acm-portal-nav-label">Luma</span>
      </a>
    </ng-conainer>    
    <ng-conainer *appShowIfAll="perm.ViewEnvestnetSso">
      <a class="pl-0 acm-portal-nav-item" href="api/sso/envestnet" target="_blank" rel="noopener" aria-label="Envestnet">
        <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="showSubNavigation ? 'Envestnet' : ''" matTooltipPosition="right">
          <img class="w-auto h-25 m-auto" src="assets/images/logos/navigation/envestnet-logo.png">
        </span>
        <span class="acm-portal-nav-label">Envestnet</span>
      </a>
    </ng-conainer>
  </div>
</nav>