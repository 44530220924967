<form @fadeIn *ngIf="account && workflow" [formGroup]="form" (ngSubmit)="onSubmit()"
  class="flex flex-col w-full h-full overflow-hidden">
  <app-account-request-modal-header #header [title]="workflow.title" [formService]="reAllocationFormService"
    [account]="account" (closeRequested)="closeRequest()"></app-account-request-modal-header>
  <div class="flex-grow w-full loader-wrap" *ngIf="isSubmitting" @fadeIn>
    <div class="loader-sm is-blue"></div>
  </div>
  <section *ngIf="!isLoading && !isSubmitting && !isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-allocation-step class="h-full" *ngIf="reAllocationFormService.currentStep === accountUpdateStepEnum.step1"
      [investmentRequestFormService]="reAllocationFormService" [parentForm]="form.controls.allocationConfiguration"
      [initialAmount]="reAllocationFormService.initialAmount"
      [requestType]="requestTypeEnum.reallocation"></app-allocation-step>
    <section class="flex flex-col p-30 h-full gap-y-20"
      *ngIf="reAllocationFormService.currentStep === accountUpdateStepEnum.step2"
      [formGroup]="form.controls.allocationConfiguration">
      <!--<app-solicitation-options [solicitationFormGroup]="form.controls.solicitation"></app-solicitation-options>-->
      <section class="flex flex-col h-full gap-y-20">
        <label class="text-base font-bold text-gray-dark required-label" for="contribution-uploads">Please upload any
          required documents below:</label>
        <app-message-notification>
          <span>If changes were made to a model's account fee, <a target="_blank" href="/api/wistia/download?form=feechange2022">please fill out
              the Fee Change Request Form</a> and upload the document below. <strong>This form is required for any fee
              changes.</strong></span>
        </app-message-notification>
        <app-file-dropper (fileChangeEvent)="uploadFiles($event)" class="mt-5"
          [value]="reAllocationFormService.files"></app-file-dropper>
      </section>
    </section>
    <app-review-step *ngIf="reAllocationFormService.currentStep === accountUpdateStepEnum.step3"
      [investmentRequestFormService]="reAllocationFormService"
      [requestType]="requestTypeEnum.reallocation"></app-review-step>
  </section>
  <section *ngIf="!isSubmitting && isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.reallocation"></app-complete>
  </section>
  <footer *ngIf="!showCustomHoldings && !isComplete">
    <button [disabled]="isSubmitting" type="button"
      class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center" (click)="cancelRequest()"
      *ngIf="reAllocationFormService.currentStep === accountUpdateStepEnum.step1">Back</button>
    <button [disabled]="isSubmitting" type="button"
      class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
      *ngIf="(reAllocationFormService.currentStep > accountUpdateStepEnum.step1)"
      (click)="reAllocationFormService.goToPreviousStep()">Back</button>
    <button [disabled]="isSubmitting" type="button" class="m-10 ml-auto button is-primary is-lg flex justify-center"
      (click)="reAllocationFormService.goToNextStep(pages[reAllocationFormService.currentStep - 1].formGroup, pages.length)"
      *ngIf="(reAllocationFormService.currentStep <= (pages.length - 1))">Next</button>
    <button [disabled]="isSubmitting" type="submit" class="m-10 ml-auto button is-primary is-lg flex justify-center"
      *ngIf="reAllocationFormService.currentStep === accountUpdateStepEnum.step3">{{isSubmitting ? "Submitting" :
      "Submit"}}</button>
  </footer>
</form>