<section *ngIf="parentForm" class="flex flex-col gap-y-30" [formGroup]="parentForm">
  <p class="font-bold">Custom Holdings Allocation: <span class="text-blue-dark">{{customHoldingsAmount | currency}}</span></p>
  <mat-checkbox *ngIf="requestType === requestTypeEnum.distribution" formControlName="sellAllCustomHoldings">Sell All
    shares of all positions</mat-checkbox>
  <div *ngIf="!parentForm.value.sellAllCustomHoldings" class="flex flex-col gap-y-10" formArrayName="allocation">
    <div class="flex text-xs font-semibold uppercase gap-x-10 text-gray">
      <span class="flex-none w-64"></span>
      <span class="flex-grow">Actions</span>
      <span class="w-96">Stock Symbols / Fund Code</span>
      <span class="w-96">Amount</span>
      <span class="w-34"></span>
    </div>
    <!-- LIST -->
    <div *ngFor="let item of customAllocationFormArray?.controls; let i = index" class="flex flex-col gap-y-5">
      <div class="flex items-center gap-x-10" [formGroupName]="i">
        <div class="flex">
          <mat-button-toggle-group formControlName="type" name="Trade Action" aria-label="Trade Action" class="button-toggle-group">
            <mat-button-toggle class="is-primary"
                               *ngIf="requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.contribution"
                               [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                               value={{Buy}}>BUY</mat-button-toggle>
            <mat-button-toggle class="is-alert"
                               *ngIf="requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.distribution"
                               [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                               value={{Sell}}>SELL</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <mat-select class="select text-sm" formControlName="action" placeholder="Select an action"
                    (selectionChange)="updateCustomHoldingValidation(item)">
          <mat-option *ngFor="let option of getTradeActionOptions(item)" [value]="option">{{option.name}}</mat-option>
        </mat-select>
        <input type="text" class="text-sm input w-96" formControlName="stockSymbol" placeholder="Stock symbol" />
        <input *ngIf="item.value.action?.allocationAmountType === Dollar" currencyMask type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
        <input *ngIf="item.value.action?.allocationAmountType !== Dollar" currencyMask [options]="customHoldingShareAmountMaskOptions" type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
        <div class="w-34">
          <button type="button" class="icon-button is-warning is-inverted"
                  matTooltip="Remove entry" (click)="removeCustomAllocation(i)">
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="item.invalid && item.touched"
           class="text-xs text-red w-full">Fields are required</div>
    </div>
    <!-- NEW HOLDING -->
    <form class="flex items-center gap-x-10" [formGroup]="newCustomHolding!" (ngSubmit)="addNewCustomAllocation()">
      <div class="flex">
        <mat-button-toggle-group formControlName="type" name="Trade Action" aria-label="Trade Action" class="button-toggle-group">
          <mat-button-toggle class="is-primary"
                             *ngIf="requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.contribution"
                             [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                             value={{Buy}}>BUY</mat-button-toggle>
          <mat-button-toggle class="is-alert"
                             *ngIf="requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.distribution"
                             [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                             value={{Sell}}>SELL</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-select class="select text-sm" formControlName="action" placeholder="Select an action"
                  (selectionChange)="updateCustomHoldingValidation(newCustomHolding!)">
        <mat-option *ngFor="let option of getTradeActionOptions(newCustomHolding!)" [value]="option">{{option.name}}</mat-option>
      </mat-select>
      <input type="text" class="text-sm input w-96" formControlName="stockSymbol" placeholder="Stock symbol" />
      <input *ngIf="newCustomHolding?.value?.action?.allocationAmountType === Dollar" currencyMask type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
      <input *ngIf="newCustomHolding?.value?.action?.allocationAmountType !== Dollar" currencyMask [options]="customHoldingShareAmountMaskOptions"type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
      <div class="min-w-34">      
        <button *ngIf="newCustomHolding?.valid && newCustomHolding?.touched" type="submit" class="icon-button is-primary is-outlined" [ngClass]="{'animate-pulse bg-blue-dark text-white' : newCustomHolding?.valid}" matTooltip="Add another entry">
          <mat-icon>add</mat-icon>
        </button>
      </div>      
    </form>    
    <div *ngIf="newCustomHolding?.invalid && newCustomHolding?.touched"
         class="text-xs text-red w-full -mt-5">Fields are required
    </div>    
  </div>
</section>