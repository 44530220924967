import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AccountUpdateStepEnum, RequestTypeEnum } from 'src/app/portal/features/account-request-workflows/shared/enums/account-update.enum';
import { ReviewModel } from 'src/app/portal/features/account-request-workflows/shared/models/review.model';
import { allocationMinimumEntriesValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/allocation-minimum-entries.validator';
import { notViewingCustomHoldingsValidator } from 'src/app/portal/features/account-request-workflows/shared/validators/not-viewing-custom-holdings.validator';
import { FileParameter, ReallocationRequestsClient } from '../../../../../core/clients/generated/client';
import { InvestmentRequestFormService } from '../../services/investment-request-form.service';
import { AllocationFormGroup } from '../../shared/models/allocation-form.models';
import { SolicitationFormGroup } from '../../shared/models/solicitation-form.model';
import { AccountUpdateStepModel } from '../../shared/models/workflow.models';
import { allocationAmountEqualValidator } from '../../shared/validators/allocation-amount-equal.validator';
import { customAllocationMinimumValidator } from '../../shared/validators/custom-allocation-minimum.validator';
import { ReallocationFormGroup } from '../models/reallocation-form.models';
import { Dollar } from '../../shared/constants/allocation-action.constant';

@Injectable()
export class ReallocationFormService extends InvestmentRequestFormService {
  private solicitationFormGroup = this.fb.group({
    isSolicited: [ true, [ Validators.required ] ],
  }) as SolicitationFormGroup;

  private allocationConfigurationFormGroup = this.fb.group({
    type: Dollar,
    isTradesOnly: false,
    sleeves: this.fb.array([], []),
    newSleeve: this.fb.group({
      model: [ null ],
      accountTotalFee: [null ],
      amount: [ null ],
      percentage: [ null ],
      searchTerm: null,
      showAddNewSleeve: false,
      isCustomHoldings: false,
    }),
    notes: this.fb.control(''),
    useCurrentSetup: false,
    sellAllEvenly: false,
    showCustomHoldings: [ false, [ notViewingCustomHoldingsValidator(this.currentStep) ] ],
    customHoldings: this.fb.group({
      notes: null,
      allocation: this.fb.array([]),
      sellAllCustomHoldings: this.fb.control(null)
    }),
  }, {
    validators: [ allocationMinimumEntriesValidator(), allocationAmountEqualValidator(() => this.initialAmount), customAllocationMinimumValidator(RequestTypeEnum.reallocation) ]
  }) as AllocationFormGroup;

  readonly form = this.fb.group({
    solicitation: this.solicitationFormGroup,
    allocationConfiguration: this.allocationConfigurationFormGroup,
  }) as ReallocationFormGroup;

  readonly pages:AccountUpdateStepModel[] = [
    {
      formGroup: this.allocationConfigurationFormGroup,
      value: AccountUpdateStepEnum.step1
    },
    {
      formGroup: this.solicitationFormGroup,
      value: AccountUpdateStepEnum.step2
    },
    {
      formGroup: this.form,
      value: AccountUpdateStepEnum.step3
    },
  ];

  readonly allocationHeader = '';
  readonly allocationFooter = 'Total Account Value';
  get allocationAmountNotEqualError(): string{
    return `Your allocations do not add up to the total account value of ${this.currencyPipe.transform(this.initialAmount) ?? 0}`;
  }

  initialAmount = 0;
  files:File[] = [];

  constructor(fb:UntypedFormBuilder, private reallocationRequestsClient:ReallocationRequestsClient, private currencyPipe:CurrencyPipe) {
    super(fb);
  }

  initSleeveInput():void {
    //this.setSleeveAmountsToCurrentValue(this.sleevesFormArray);
    this.sleevesFormArray.enable();
  }

  postRequest(accountID:number): Observable<number> {
    const reallocationRequest = {
      investmentAccountID: accountID,
      isSolicited: this.solicitationFormGroup.value.isSolicited,
      notes: this.allocationConfigurationFormGroup.value.notes,
      //requestSleeves: this.allocationConfigurationFormGroup.controls.sleeves.value.filter(x => x.amount).map((x) => {
      requestSleeves: this.allocationConfigurationFormGroup.controls.isTradesOnly.value === false ? this.allocationConfigurationFormGroup.controls.sleeves.value.map((x) => {
        return {
          investmentAccountModelID: x.id,
          accountNumber: x.accountNumber,
          amount: x.amount ?? 0,
          accountTotalFee: x.amount ? Number((x.accountTotalFee / 100).toFixed(5)) : 0, // convert percentage back to decimal on submit
          isNewSleeve: x.isNewSleeve
        };
      }) : [],
      tradeInstruction: (this.allocationConfigurationFormGroup.value.customHoldings.allocation.length || this.allocationConfigurationFormGroup.value.customHoldings.notes?.length) ? {
        tradeInstructionID: this.allocationConfigurationFormGroup.value.customHoldings.sellAllCustomHoldings ? 1 : 3,
        notes: this.allocationConfigurationFormGroup.value.customHoldings.notes,
        tradeInstructionOptions: this.allocationConfigurationFormGroup.value.customHoldings.allocation.map((x) => {
          return {
            tradeInstructionOptionID: x.action.tradeInstructionOptionID,
            symbol: x.stockSymbol,
            amount: x.amount
          };
        })
      } : undefined
    };
    const files: FileParameter[] = this.files?.map(file => {
      return { data: file, fileName: file.name };
    }) || [];
    return this.reallocationRequestsClient.createReallocationRequest(files, reallocationRequest);
  }

  getReviewModel(): ReviewModel {
    const allocationConfigurationFormGroup = this.form.controls.allocationConfiguration as AllocationFormGroup;
    const customHoldingsAmount = allocationConfigurationFormGroup.controls.sleeves.value.find(x => x.isCustomSleeve)?.amount;
    const customHoldingsAccountFee = allocationConfigurationFormGroup.controls.sleeves.value.find(x => x.isCustomSleeve)?.accountTotalFee;
    const total = allocationConfigurationFormGroup.controls.sleeves.value.reduce((acc, current) => acc + current.amount, 0);

    return {
      isTradesOnly: allocationConfigurationFormGroup.controls.isTradesOnly.value as boolean,
      amount: total,
      sleeves: allocationConfigurationFormGroup.controls.sleeves.value.filter(x => !x.isCustomSleeve && x.amount).map((x)=>{
        return {
          name: x.model,
          accountTotalFee: x.accountTotalFee,
          amount: x.amount,
          percent: x.amount / total
        };
      }),
      customHoldings: (customHoldingsAmount || allocationConfigurationFormGroup.controls.isTradesOnly.value === true) ? {
        amount: customHoldingsAmount,
        accountTotalFee: customHoldingsAccountFee,
        percent: customHoldingsAmount ?? 0 / total,
        holdings: allocationConfigurationFormGroup.value.customHoldings.allocation.map((x)=>{
          return {
            name: x.action.isStock ? x.stockSymbol : x.action.name,
            type: x.type,
            amount: (!x.action.isStock && x.action.allocationAmountType === 'Dollar') ? x.amount : undefined,
            shareCount: (x.action.isStock || x.action.allocationAmountType === 'Share') ? x.amount : undefined,
          };
        }),
        notes:  allocationConfigurationFormGroup.value.customHoldings.notes,
      } : undefined,
      notes: allocationConfigurationFormGroup.value.notes,
      files: this.files
    };
  }
}
