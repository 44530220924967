import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate, CanActivateChild {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  canActivate(): boolean | UrlTree {
    return this.checkSession();
  }

  canActivateChild(): boolean | UrlTree {
    return this.checkSession();
  }

  private checkSession(): boolean | UrlTree {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }

    return this.router.parseUrl('/login');
  }
}
