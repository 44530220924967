<form [formGroup]="firstMfaVerificationForm" class="flex flex-col flex-auto" (submit)="onSubmit()">
    <div>
        <h4 class="leading-tight text-2xl font-normal text-center m-30">Multi-Factor Authentication Setup</h4>
    </div>
    <div class="my-10 mx-32 p-10 md:p-20 text-gray-dark text-lg  bg-blue-lightest rounded">
        <p>We have sent a text message with a verification code to the phone number you provided. Once you have received a verification code on your device, please enter it in the box&nbsp;below.</p>
    </div>
    <div class="my-10 mx-32 flex flex-shrink-0 flex-col">
        <label class="label mb-5" for="mfaCode">PIN</label>
        <input type="text" name="mfaCode" autocomplete="off" formControlName="mfaCode" maxlength="6" autofocus class="input" placeholder="6-digit code">
        <span *ngIf="formSubmitting && firstMfaVerificationForm.invalid" class="text-sm mt-5 text-red">
            <span *ngIf="firstMfaVerificationForm.controls.mfaCode.hasError('required')">This field is required</span>
            <span *ngIf="firstMfaVerificationForm.controls.mfaCode.hasError('mfaInvalid')">Please enter a valid 6-digit PIN</span>
        </span>
    </div>
    <div class="my-10 mx-32 flex flex-shrink-0 flex-col">
        <mat-checkbox name="rememberMe" formControlName="rememberMe" class="text-sm text-gray">
            Remember this computer. You won't need to re-enter your PIN for 30 days.
        </mat-checkbox>
    </div>
    <div class="mx-32 my-30 flex flex-col flex-none">
        <button class="button is-primary is-outlined focus:ring-0" mat-button type="submit">Submit</button>
    </div>
</form>