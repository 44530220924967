import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ToastClassEnum } from '../../../core/services/snackbar/snackbar.models';
import { AgentModel, AgentsClient } from 'src/app/core/clients/generated/client';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from '../../constants/animations';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, catchError, debounceTime, distinctUntilChanged, forkJoin, map, startWith } from 'rxjs';
import { SsoAgentSelectDialogData } from '../../models/generic.models';
import { search } from '../../helpers/search.helpers';

@Component({
  animations: [fadeIn],
  selector: 'app-sso-agent-select-modal',
  templateUrl: './sso-agent-select-modal.component.html',
})
export class OpenSsoAgentSelectModalComponent implements OnInit, AfterViewInit {
  loggedInAdvisor?: AgentModel;
  agents: AgentModel[] = [];
  filteredAgents?: Observable<AgentModel[]>;
  isLoading = false;
  selectedAgentID: number | null = null;
  ssoBaseUrl = '';
  ssoUrl = '';
  selectedAgentFormGroup = this.formBuilder.group({
    agent: [null, [Validators.required]],
  }) as SelectedAgentFormGroup;

  constructor(
    private agentsApi: AgentsClient,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<OpenSsoAgentSelectModalComponent>,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: SsoAgentSelectDialogData,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.ssoBaseUrl = this.data.BaseUrl;
    this.getData();
  }

  filterAgents(searchString: string): AgentModel[] {
    return search(this.agents, searchString, [ 'firstName', 'lastName' ]);
  }

  private getData(): void {
    const loggedInAdvisorApiCall: Observable<AgentModel> = this.agentsApi.getAgentSelf().pipe(catchError(error => {
      throw error;
    }));
    const advisorListApiCall: Observable<AgentModel[]> = this.agentsApi.getParentAgents(true).pipe(catchError(error => {
      throw error;
    }));
    const promises = {
      loggedInAdvisor: loggedInAdvisorApiCall,
      advisorList: advisorListApiCall
    };
    forkJoin(promises)
      .subscribe({
        next: (result) => {
          this.loggedInAdvisor = result.loggedInAdvisor;
          this.agents = result.advisorList;

          this.filteredAgents = this.selectedAgentFormGroup.get('agent')?.valueChanges.pipe(
            startWith(this.agents[ 0 ]),
            debounceTime(200),
            distinctUntilChanged(),
            map((searchTerm: string) => this.filterAgents(searchTerm).sort((a, b) => a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0)));

          if (this.agents.length === 1) {
            this.selectedAgentID = this.loggedInAdvisor?.agentID;
            this.updateSsoUrl(this.selectedAgentID);
            this.redirectToSso();
          }

          const found = this.agents.find((obj) => {
            return obj.agentID === this.loggedInAdvisor?.agentID;
          });

          if (found !== undefined) {
            this.selectedAgentID = this.loggedInAdvisor?.agentID;
            this.updateSsoUrl(this.selectedAgentID);

            this.selectedAgentFormGroup.setValue({
              agent: {
                agentID: this.loggedInAdvisor?.agentID,
                firstName: this.loggedInAdvisor?.firstName,
                lastName: this.loggedInAdvisor?.lastName
              }
            });
          }
        },
        error: () => {
          this.snackbarService.openSnackbar('Error retrieving information. Please try again later.', ToastClassEnum.warning);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit(): void {
    const width = '50rem';
    const height = '28rem';
    this.dialogRef.updateSize(width, height);
  }

  updateSsoUrl(agentId: number | null): void {
    if (agentId) {
      this.ssoUrl = this.ssoBaseUrl + agentId;
    } else {
      this.ssoUrl = this.ssoBaseUrl;
    }
  }

  onChangeModelSearch($event: MatAutocompleteSelectedEvent): void {
    const agent = $event.option.value as AgentModel;
    this.selectedAgentID = agent.agentID;
    this.updateSsoUrl(agent.agentID);
  }

  displayModel(agent: AgentModel): string {
    if (agent) {
      return agent.firstName + ' ' + agent.lastName;
    } else {
      return '';
    }
  }

  resetSelectedAgent(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      this.selectedAgentID = null;
    }
  }

  redirectToSso(): void {
    window.open(this.ssoUrl, '_blank');
    this.dialogRef.close();
  }
}

export interface SelectedAgentFormGroup extends UntypedFormGroup {
  value: {
    agent: AgentModel;
  };
  controls: {
    agent: UntypedFormControl;
  }
}
