import { Component, Input } from '@angular/core';
import { ContributionFundsFormGroup } from '../../models/contribute-funds-form-group';

@Component({
  selector: 'app-contribute-funds',
  templateUrl: './contribute-funds.component.html',
})
export class ContributeFundsComponent {
  @Input() parentForm?: ContributionFundsFormGroup;
  instruction = 'Start by entering the amount to contribute to the client\'s account.';
}
