<form @fadeIn [formGroup]="paperApplicationForm" class="flex flex-col w-full h-full" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
  <header>
    <h2 class="m-10 mr-20 text-lg font-bold">Submit Paperwork for Account Opening</h2>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" [disabled]="isSubmitting" type="button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="flex-grow w-full loader-wrap" *ngIf="isSubmitting && !isSubmitted" @fadeIn>
    <div class="loader-sm is-blue"></div>
  </div>
  <section class="" *ngIf="!isSubmitting && isSubmitted">
    <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-500" *ngIf="isComplete === true">
        <span class="material-icons mi-96 text-green-dark">task_alt</span>
        <label class="font-bold">Thank You!</label>
        <label class="font-semibold">Your new paper application request has been submitted successfully.</label>      
        <button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
                (click)="closeRequest()">Close</button>
    </div>
    <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-300" *ngIf="isComplete === false">
        <span class="material-icons mi-96 text-red-dark">error</span>
        <label class="font-bold">Uh Oh!</label>
        <label class="font-semibold">Something went wrong with submitting your paper application request.</label>
        <label class="font-semibold">Please Try submitting again.</label>     
    </div>
  </section>
  <section *ngIf="!isSubmitting && !isSubmitted" class="flex flex-col flex-grow p-30 overflow-auto gap-y-20">
    <div class="flex flex-col flex-none">
      <span class="text-lg text-gray-dark">{{instruction}}</span>
    </div>
    <!-- IAR and Client -->
    <div class="flex flex-col flex-none gap-y-10">
      <div class="flex flex-row justify-between" [ngClass]="{'gap-x-10': paperApplicationForm.get('Iar')?.valid, 'pr-10': !paperApplicationForm.get('Iar')?.valid}">
        <div class="flex flex-col w-1/2">
          <label class="mb-5 text-sm text-gray required-label">IAR</label>
          <mat-select class="select" (selectionChange)="onChangeIars()" formControlName='Iar' name="iar" placeholder="Select IAR" aria-label="Select IAR">
            <mat-option *ngFor="let iar of iars" [value]="iar.agentID">{{iar.firstName + ' ' + iar.lastName}}</mat-option>
          </mat-select>
          <span *ngIf="paperApplicationForm.controls['Iar'].touched && paperApplicationForm.controls['Iar'].errors" class="w-full mt-5 text-xs text-red">
            <span *ngIf="paperApplicationForm.controls['Iar'].hasError('required')">This field is required.</span>
          </span>
        </div>
        <div class="flex flex-col w-1/2" *ngIf="paperApplicationForm.get('Iar')?.valid">
          <label class="mb-5 text-sm text-gray required-label">Client</label>
          <input class="w-full input" type="text" matInput placeholder="Select a Client" formControlName="Client" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayClient" (optionSelected)="onClientChange($event)">
            <mat-option [value]="newClient">
              <span class="flex items-center gap-x-10 text-blue">
                <span>{{newClient.firstName}}</span>
                <mat-icon svgIcon="plus"></mat-icon>
              </span>
            </mat-option>
            <mat-option class="capitalize" *ngFor="let client of filteredClients | async " [value]="client">
              {{client.lastName + ', ' + client.firstName}}
            </mat-option>
          </mat-autocomplete>
          <span *ngIf="paperApplicationForm.controls['Client'].touched && paperApplicationForm.controls['Client'].errors" class="w-full mt-5 text-xs text-red">
            <span *ngIf="paperApplicationForm.controls['Client'].hasError('required')">This field is required.</span>
          </span>
        </div>
      </div>
      <!-- New Client Information -->
      <div class="flex flex-col flex-none gap-y-10" *ngIf="paperApplicationForm.get('NewClient')" formGroupName='NewClient'>
        <div class="flex flex-row gap-x-10 justify-between">
          <div class="flex flex-col w-1/2">
            <label class="mb-5 text-sm text-gray required-label">New Client's First Name</label>
            <input type="text"
                   matInput
                   class="w-full input"
                   placeholder="First Name"
                   formControlName="FirstName">
            <span *ngIf="paperApplicationForm.controls['NewClient'].get('FirstName')?.touched && paperApplicationForm.controls['NewClient'].get('FirstName')?.errors" class="w-full mt-5 text-xs text-red">
              <span *ngIf="paperApplicationForm.controls['NewClient'].get('FirstName')?.hasError('required')">This field is required.</span>
            </span>
          </div>
          <div class="flex flex-col w-1/2">
            <label class="mb-5 text-sm text-gray required-label">New Client's Last Name</label>
            <input type="text"
                   matInput
                   class="w-full input"
                   placeholder="Last Name"
                   formControlName="LastName">
            <span *ngIf="paperApplicationForm.controls['NewClient'].get('LastName')?.touched && paperApplicationForm.controls['NewClient'].get('LastName')?.errors" class="w-full mt-5 text-xs text-red">
              <span *ngIf="paperApplicationForm.controls['NewClient'].get('LastName')?.hasError('required')">This field is required.</span>
            </span>
          </div>
        </div>
        <div class="flex flex-col">
          <label class="mb-5 text-sm text-gray required-label">New Client's Email</label>
          <input type="text"
                 matInput
                 class="w-full input"
                 placeholder="Email"
                 formControlName="Email">
          <span *ngIf="paperApplicationForm.controls['NewClient'].get('Email')?.touched && paperApplicationForm.controls['NewClient'].get('Email')?.errors" class="w-full mt-5 text-xs text-red">
            <span *ngIf="paperApplicationForm.controls['NewClient'].get('Email')?.hasError('required')">This field is required.</span>
          </span>
        </div>
      </div>
    </div>
    <!-- Custodian and Account Type -->
    <div class="flex flex-col flex-none">
      <div class="flex flex-row gap-x-10 items-stretch">
        <div class="flex flex-col w-full">
          <label class="mb-5 text-sm text-gray required-label">Custodian</label>
          <mat-select class="select"
                      formControlName='CustodianID' name="custodianID"
                      placeholder="Select a Custodian" aria-label="Select Custodian">
            <mat-option *ngFor="let custodian of custodians" [value]="custodian.custodianID">{{custodian.name}}</mat-option>
          </mat-select>
          <span *ngIf="paperApplicationForm.controls['CustodianID'].touched && paperApplicationForm.controls['CustodianID'].errors" class="w-full mt-5 text-xs text-red">
            <span *ngIf="paperApplicationForm.controls['CustodianID'].hasError('required')">This field is required.</span>
          </span>
        </div>
        <div class="flex flex-col w-full">
          <label class="mb-5 text-sm text-gray required-label">Account Type</label>
          <mat-select class="select"
                      formControlName='InvestmentAccountTypeID' name="investmentAccountTypeID"
                      placeholder="Select an Account Type" aria-label="Select an Account Type">
            <mat-option *ngFor="let investmentAccountType of investmentAccountTypes" [value]="investmentAccountType.id">{{investmentAccountType.name}}</mat-option>
          </mat-select>
          <span *ngIf="paperApplicationForm.controls['InvestmentAccountTypeID'].touched && paperApplicationForm.controls['InvestmentAccountTypeID'].errors" class="w-full mt-5 text-xs text-red">
            <span *ngIf="paperApplicationForm.controls['InvestmentAccountTypeID'].hasError('required')">This field is required.</span>
          </span>
        </div>
      </div>
    </div>
    <!-- Model -->
    <div class="flex flex-col flex-none" *ngIf="paperApplicationForm.get('Iar')?.valid">
      <div class="flex flex-col w-full">
        <label class="mb-5 text-sm text-gray required-label">Model</label>
        <mat-select class="select"
                    formControlName='InvestmentAccountModelID' name="investmentAccountModelID"
                    placeholder="Select a Model" aria-label="Select a Model">
          <mat-option *ngFor="let model of investmentModels" [value]="model.id">{{model.name}}</mat-option>
          <mat-option *ngIf="!investmentModels.length">No investment models available for selected IAR</mat-option>
        </mat-select>
        <span *ngIf="paperApplicationForm.controls['InvestmentAccountModelID'].touched && paperApplicationForm.controls['InvestmentAccountModelID'].errors" class="w-full mt-5 text-xs text-red">
          <span *ngIf="paperApplicationForm.controls['InvestmentAccountModelID'].hasError('required')">This field is required.</span>
        </span>
      </div>
    </div>
    <!-- Notes -->
    <div class="flex flex-col flex-none">
      <label class="mb-5 text-sm text-gray required-label">Notes</label>
      <textarea class="textarea" rows="4"
                placeholder="Enter Notes Here"
                aria-label="Notes input" formControlName='Notes' name="notes"></textarea>
    </div>
    <!-- Documents -->
    <div class="flex flex-col flex-none">
      <label class="mb-5 text-sm text-gray required-label">Uploads</label>
      <div class="flex flex-col flex-none pb-10 relative">
        <app-file-dropper (fileChangeEvent)="uploadFiles($event)"></app-file-dropper>
        <span *ngIf="paperApplicationForm.controls['Files'].touched && paperApplicationForm.controls['Files'].errors" class="w-full mt-5 text-xs text-red absolute top-100 left-0">
          <span *ngIf="paperApplicationForm.controls['Files'].hasError('required')">This field is required.</span>
        </span>
      </div>
    </div>
  </section>
  <footer *ngIf="!isSubmitting && !isComplete">
    <button mat-button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined" mat-dialog-close [disabled]="isSubmitting">Cancel</button>
    <button mat-button type="submit" class="m-10 button is-primary is-lg ml-auto" [disabled]="isSubmitting">{{isSubmitting ? "Submitting" : "Submit"}}</button>
  </footer>
</form>
<div @fadeIn class="flex-grow w-full loader-wrap" *ngIf="isLoading">
  <div class="loader-sm is-blue"></div>
</div>