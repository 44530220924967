
<section class="flex flex-col w-full h-full p-20 bg-top bg-no-repeat bg-cover md:p-30" style="background: url(./assets/images/wave-bg.svg)">
  <!-- <h1 class="text-lg font-bold capitalize">Investment Strategies</h1> -->
  <section class="flex lg:flex-row flex-col gap-x-20 lg:gap-x-30 gap-y-20 overflow-hidden h-full">
    <section class="overflow-hidden w-full card flex flex-col" @fadeIn>
      <div class="flex items-center p-20 border-b border-solid border-gray-lighter" *ngIf="!isLoading">
        <h2 class="flex items-center mr-70 text-lg font-bold"><span>Investment Strategies</span></h2>
        <div class="relative flex w-full pr-10 m-10 input pl-30 md:w-300 focus:ring-2 focus:ring-blue-light">
          <mat-icon svgIcon="magnify" class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18"></mat-icon>
          <input
                 type="text"
                 class="w-full ml-5 font-medium text-sm outline-none appearance-none text-gray-dark"
                 placeholder="Find a model..."
                 [(ngModel)]="searchTerm"
                 (ngModelChange)="searchTermChanged($event)" />
        </div>
        <button mat-button class="flex items-center justify-center m-10 ml-auto button is-primary is-inverted" aria-label="Toggle Filters"
                (click)="investmentFilterSidenav.toggle()">
          <mat-icon svgIcon="filter" class="mr-10 mi-18"></mat-icon>
          <span class="mr-5">Filters</span>
        </button>
      </div>
      <mat-sidenav-container autosize class="flex h-full overflow-hidden bg-gradient-to-t from-gray-lighter to-gray-lightest" @fadeIn>
        <mat-sidenav-content class="flex flex-col flex-grow overflow-hidden">
          <div *ngIf="filteredInvestmentStrategies.length" class="grid gap-10 p-20 overflow-auto grid-cols-3 xl:grid-cols-4 md:gap-20">
            <app-investment-model [id]="'investmentModel' + index" *ngFor="let model of filteredInvestmentStrategies; let index = index" class="flex flex-col" [model]="model" [wistiaProject]="wistiaProject"></app-investment-model>
          </div>
          <div *ngIf="!filteredInvestmentStrategies.length" class="h-full w-full flex flex-col">
            <h2 *ngIf="!filteredInvestmentStrategies.length" class="font-medium text-gray m-auto p-30">No results found.</h2>
          </div>
        </mat-sidenav-content>
        <mat-sidenav #investmentFilterSidenav [opened]="sidenavConfig.IsOpened" [mode]="sidenavConfig.Mode" class="flex flex-col w-300 gap-y-10" position="end">
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <div class="flex flex-row items-center justify-between">
              <label class="text-sm text-gray">Risk Tolerance</label>
              <button (click)="clearFilters()" mat-button class="flex flex-row max-h-25 ml-auto justify-around capitalize text-sm border button p-0 font-medium is-primary is-inverted is-outlined border-gray text-gray"
                      [style.visibility]="!hasActiveFilters && !searchTerm ? 'hidden' : ''">Clear All</button>
            </div>
            <mat-checkbox class="flex py-2" *ngFor="let item of riskToleranceFilters" [(ngModel)]="item.Checked" (ngModelChange)="updateActiveFilters(item, enforceFilters.RiskTolerance)">
              {{item.Name}}
            </mat-checkbox>
          </section>
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10" *appHideIfOne="perm.ViewEvolvExperience">
            <label class="text-sm text-gray">Money Manager</label>
            <mat-select class="select" [(ngModel)]="enforceFilters.MoneyManager" (ngModelChange)="updateFilteredList()" [multiple]="true" placeholder="Showing All" panelClass="p-10 rounded shadow-lg">
              <mat-option *ngFor="let item of moneyManagerFilters" [value]="item.Name">{{item.Name}}</mat-option>
            </mat-select>
          </section>
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <label class="text-sm text-gray">Manager Fee</label>
            <mat-select class="select" [(ngModel)]="enforceFilters.ManagerFee" (ngModelChange)="updateFilteredList()" [multiple]="true" placeholder="Showing All" panelClass="p-10 rounded shadow-lg">
              <mat-option *ngFor="let item of managerFeeFilters" [value]="item.Name">{{item.Name}} bps</mat-option>
            </mat-select>
          </section>
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <label class="text-sm text-gray">Model Minimum</label>
            <mat-select class="select" [(ngModel)]="enforceFilters.ModelMinimum" (ngModelChange)="updateFilteredList()" [multiple]="true" placeholder="Showing All" panelClass="p-10 rounded shadow-lg">
              <mat-option *ngFor="let item of modelMinimumFilters" [value]="item.Name">{{item.Name | currency}}</mat-option>
            </mat-select>
          </section>
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <label class="text-sm text-gray">Tags</label>
            <div class="flex flex-wrap gap-x-10 gap-y-10">
              <button mat-button *ngFor="let item of tagFilters" class="flex items-center justify-center px-10 py-5 text-xs font-semibold uppercase rounded-full is-sm"
                      (click)="toggleTag(item)" [ngClass]="item.Checked ? 'is-primary' : 'bg-gray-lighter hover:bg-blue-lighter'">
                {{item.Name}}
              </button>
            </div>
          </section>
        </mat-sidenav>
      </mat-sidenav-container>
    </section>
    <section class="flex-none overflow-hidden card" @fadeIn>
      <div class="flex items-center p-20 border-b border-solid border-gray-lighter">
        <h2 class="flex-none m-10 text-lg font-bold">Additional Offerings</h2>
      </div>
      <div class="h-full p-20 overflow-auto">
        <div *ngFor="let model of additionalOfferings" class="flex flex-row hover:bg-gray-lightest rounded cursor-pointer p-15" (click)="openInvestmentDetailsModal(model)">
          <mat-icon svgIcon="file-multiple" class="flex-none pr-10 fill-gray"></mat-icon>
          <div class="flex flex-col">
            <h3 class="font-bold">{{model.Name}}</h3>
          </div>
        </div>
      </div>
    </section>
  </section>
</section>