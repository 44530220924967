<div class="flex flex-col w-full h-full">
  <div *ngIf="modalLoading" class="flex-grow w-full loader-wrap" @fadeIn>
    <div class="loader-sm is-blue"></div>
  </div>
  <ng-container *ngIf="!modalLoading && selectedWorkflow === requestTypeEnum.accountSelection">
    <header @fadeIn>
      <h2 class="m-10 mr-20 text-lg font-bold">Account Update</h2>
      <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" type="button" mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
    </header>
    <section class="m-10 dialog-content gap-y-30" @fadeIn>
      <div class="flex flex-col">
        <span class="mb-20 font-bold required-label">
          Which account are you requesting an update for?</span>
        <form [formGroup]="searchField" class="relative flex justify-center">
          <input name="searchTextAccount" aria-label="Search Accounts input" matInput
            placeholder="Search for an account..." formControlName="searchInput" type="text"
            [matAutocomplete]="accountauto" class="w-full input is-lg" required>
          <button mat-button
            class="absolute right-0 h-full m-2 icon-button is-primary is-inverted text-gray-dark hover:bg-transparent"
            type="button" *ngIf="selectedAccountId || searchField.controls.searchInput.value" (click)="resetSearch()">
            <span class="material-icons mi-24">close</span>
          </button>
        </form>
        <mat-autocomplete #accountauto="matAutocomplete" ngDefaultControl>
          <ng-container
            *ngIf="searchField.controls.searchInput.value && searchField.controls.searchInput.value.length > 2 && !selectedAccountId">
            <ng-container *ngIf="!searchLoading">
              <mat-option class="flex" *ngFor="let account of accounts"
                [value]="account.custodian + (!!account.accountNumber ? ' - ' + account.accountNumber : '') + ' - ' + account.clients"
                (click)="selectAccount(account)" @fadeIn>
                <div class="flex">
                  <div class="flex flex-col pr-20">
                    <span class="mb-5 font-bold text-gray-dark" [textContent]="account.investmentAccountModel"></span>
                    <span class="text-gray">
                      {{account.custodian}}
                      {{account.accountNumber && '-'}}
                      {{account.accountNumber || ''}}
                      {{account.investmentAccountType && '-'}}
                      {{account.investmentAccountType || ''}}
                    </span>
                  </div>
                  <div class="ml-auto">
                    <span class="font-bold" [textContent]="account.clients"></span>
                  </div>
                </div>
              </mat-option>
              <mat-option class="bg-gray-lightest" readonly fadeIn *ngIf="accounts.length === 0">
                <span class="text-sm text-gray">
                  No results found.
                </span>
              </mat-option>
            </ng-container>
            <mat-option readonly disabled *ngIf="searchLoading" @fadeIn>
              <div class="flex-grow w-full loader-wrap">
                <div class="loader-sm is-blue"></div>
              </div>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <div *ngIf="searchField.invalid && searchField.touched" class="mt-5 text-xs text-red">
          This field is required.
        </div>
        <div class="flex flex-col">
          <span class="mb-20 font-bold mt-15">Select the type of request:</span>
          <div class="flex flex-col w-full gap-y-10 mb-30">
            <ng-container *ngFor="let navItem of workflowNavItems">
              <button mat-button
                class="button {{!selectedAccountId ? 'disabled bg-gray-lighter' : selectedWorkflowNavItem?.title === navItem.title ? 'is-outlined bg-blue-lightest font-bold ' : ''}} {{navItem.buttonClass}} is-inverted font-semibold flex items-center"
                [matTooltip]="'Click to go to ' + navItem.title.toLowerCase()" matTooltipPosition="right" (click)="selectedWorkflowNavItem = navItem"
                matTooltipShowDelay="1000" *ngIf="navItem.order !== requestTypeEnum.planningDesk">
                <i class="mr-20 material-icons mi-22" [style.transform]="navItem.iconTransform" [textContent]="navItem.icon"></i>
                <span [textContent]="navItem.title"></span>
              </button>
              <!-- <button mat-button class="button {{!selectedAccountId ? 'disabled bg-gray-lighter' : navItem.buttonClass}} is-inverted font-semibold flex items-center {{ selectedWorkflowNavItem?.title === navItem.title ? 'is-outlined bg-blue-lightest font-bold' : ''}}"
                [matTooltip]="'Click to go to ' + navItem.title.toLowerCase()" matTooltipPosition="right"
                matTooltipShowDelay="1000" (click)="selectedWorkflow = navItem.order; selectedWorkflowNavItem = navItem"
                *ngIf="navItem.order === requestTypeEnum.planningDesk">
                <i class="mr-20 material-icons mi-20" [textContent]="navItem.icon"></i>
                <span [textContent]="navItem.title"></span>
              </button> -->
            </ng-container>
          </div>
          <span class="mb-20 font-bold mt-5">Select who initiated this request?</span>
          <div class="flex flex-col w-full gap-y-10">           
            <ng-container *ngFor="let item of solicitationOptionItems">
              <button mat-button class="button {{!selectedAccountId ? 'disabled bg-gray-lighter' : item.buttonClass}} font-semibold is-inverted flex items-center {{ selectedSolicitationOption?.title === item.title ? 'is-outlined bg-blue-lightest font-bold' : ''}}"
                [matTooltip]="'Click to go to ' + item.title" matTooltipPosition="right" matTooltipShowDelay="1000" (click)="selectedSolicitationOption = item">
                <i class="mr-20 material-icons mi-20" [textContent]="item.icon"></i>
                <span [textContent]="item.title"></span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
    <footer @fadeIn>      
      <!--<button mat-button class="flex m-10 ml-auto border button is-primary is-inverted is-lg is-outlined"  mat-dialog-close>Cancel
        Request</button>-->      
        <button [disabled]="!selectedWorkflowNavItem  || !selectedSolicitationOption" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
        (click)="selectNavItem(selectedWorkflowNavItem, selectedSolicitationOption)">Next</button>
    </footer>
  </ng-container>
  <ng-container *ngIf="selectedWorkflowNavItem && selectedWorkflow !== requestTypeEnum.accountSelection">
    <ng-container *ngIf="account">
      <app-contribution-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
        *ngIf="selectedWorkflow === requestTypeEnum.contribution" @fadeIn></app-contribution-request>
      <app-distribution-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
        *ngIf="selectedWorkflow === requestTypeEnum.distribution" @fadeIn></app-distribution-request>
      <app-reallocation-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
        *ngIf="selectedWorkflow === requestTypeEnum.reallocation" @fadeIn></app-reallocation-request>
      <app-close-account-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
        *ngIf="selectedWorkflow === requestTypeEnum.closeAccount" @fadeIn></app-close-account-request>
    </ng-container>
    <app-planning-desk-request [workflow]="selectedWorkflowNavItem" (canceled)="resetEvent()"
      *ngIf="selectedWorkflow===requestTypeEnum.planningDesk" @fadeIn>
    </app-planning-desk-request>
  </ng-container>
</div>