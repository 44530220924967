<ng-container *ngIf="parentForm">
  <div class="flex flex-col w-full">
    <div class="flex flex-col overflow-auto p-30 gap-y-30">
      <div class="flex flex-col gap-y-30" [formGroup]="parentForm">

        <div class="flex flex-col">
          <span class="text-lg text-gray-dark">{{instruction}}</span>
        </div>

        <div class="flex flex-col gap-y-20">
          <label class="text-base font-bold text-gray-dark required-label">How much would you like to contribute?</label>
          <input autocomplete="off" currencyMask type="text" class="w-full input" formControlName="contributionAmount" placeholder="$0.00" autofocus>
          <span
                *ngIf="parentForm.controls.contributionAmount.touched && parentForm.controls.contributionAmount.errors "
                class="w-full text-xs -mt-15 text-red">
            <span *ngIf="parentForm.controls.contributionAmount.errors?.['required']">This field is
              required.</span>
            <span *ngIf="parentForm.controls.contributionAmount.errors?.['pattern']">Please use a valid currency format (i.e., "$1,000.00")</span>
          </span>
        </div>

        <!--<app-solicitation-options [solicitationFormGroup]="parentForm"></app-solicitation-options>-->

      </div>
    </div>
  </div>
</ng-container>