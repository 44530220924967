<nav routerLinkActive="is-active" class="acm-portal-subnav subnav-absolute">
  <ng-container *ngFor="let item of subNavigationItems">
    <a class="pl-0 acm-portal-nav-item" [routerLink]="'/' + item.navigation?.absolutePath" routerLinkActive="is-active">
      <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="item.navigation!.name"
            matTooltipPosition="right">
        <mat-icon class="mi-18" [svgIcon]="item.navigation!.icon" *ngIf="item.navigation!.icon"></mat-icon>
        <i class="fig-18px {{item.navigation!.name}}" *ngIf="item.navigation!.icon"></i>
      </span>
      <span class="acm-portal-nav-label" *ngIf="item.navigation">{{item.navigation.name}}</span>
    </a>
  </ng-container>
</nav>