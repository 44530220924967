<form @fadeIn *ngIf="account && workflow" [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
  <app-account-request-modal-header #header [title]="workflow.title" [account]="account" [formService]="contributionFormService" (closeRequested)="closeRequest()"></app-account-request-modal-header>
  <div class="flex-grow w-full loader-wrap" *ngIf="isSubmitting" @fadeIn>
    <div class="loader-sm is-blue"></div>
  </div>
  <section *ngIf="!isSubmitting && !isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-contribute-funds *ngIf="contributionFormService.currentStep === accountUpdateStepEnum.step1" [parentForm]="contributeFundsFormGroup"></app-contribute-funds>
    <app-allocation-step class="h-full" *ngIf="contributionFormService.currentStep === accountUpdateStepEnum.step2" [investmentRequestFormService]="contributionFormService" [parentForm]="allocationFormGroup" [initialAmount]="initialAmount" [requestType]="requestTypeEnum.contribution"></app-allocation-step>
    <app-delivery-methods *ngIf="contributionFormService.currentStep === accountUpdateStepEnum.step3" [parentForm]="deliveryOptionsFormGroup" [requestType]="requestTypeEnum.contribution"></app-delivery-methods>
    <app-review-step *ngIf="contributionFormService.currentStep === accountUpdateStepEnum.step4" [investmentRequestFormService]="contributionFormService" [requestType]="requestTypeEnum.contribution"></app-review-step>
  </section>
  <section *ngIf="!isSubmitting && isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-complete [complete]="isComplete"  [requestType]="requestTypeEnum.contribution"></app-complete>
  </section>
  <footer *ngIf="!showCustomHoldings && !isComplete">
    <button [disabled]="isSubmitting" type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            (click)="cancelRequest()" *ngIf="contributionFormService.currentStep === accountUpdateStepEnum.step1">Back</button>   
    <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center" *ngIf="(contributionFormService.currentStep > accountUpdateStepEnum.step1)"
            (click)="contributionFormService.goToPreviousStep(); isSubmitted=false;">Back</button>
    <button [disabled]="isSubmitting" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
            *ngIf="(contributionFormService.currentStep <= (pages.length - 1))" (click)="contributionFormService.goToNextStep(pages[contributionFormService.currentStep - 1].formGroup, pages.length)">Next</button>
    <button [disabled]="isSubmitting" type="submit" class="m-10 ml-auto button is-primary is-lg flex justify-center" *ngIf="contributionFormService.currentStep === accountUpdateStepEnum.step4">{{isSubmitting ? "Submitting" : "Submit"}}</button>
    </footer>
</form>