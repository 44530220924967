<cdk-virtual-scroll-viewport itemSize="81" class="w-full h-full" *ngIf="!isLoading">
  <table class="w-full p-20 -mt-20 table-auto md:p-30 md:-mt-20 custom-table has-accordion-rows" @fadeIn>
    <thead>
      <tr>
        <td
          class="sticky z-10 p-10 font-semibold uppercase bg-white text-2xs text-gray dark:bg-gray-darker dark:text-white whitespace-nowrap"
          [style.top]="offsetTranslate">&nbsp;
        </td>
        <ng-container *ngFor="let cell of table.Header; let last=last">
          <td [style.top]="offsetTranslate"
            class="sticky z-10 p-10 text-2xs font-semibold uppercase bg-white hover:bg-blue-lightest text-gray dark:bg-gray-darker dark:text-white whitespace-nowrap {{cell.WidthClass}}"
            (click)="table.orderBy(cell);">
            <span class="flex items-center cursor-pointer min-h-24 focus:outline-none"
              attr.aria-label="Sort by {{cell.DisplayName}}">
              <span class="mr-5">{{cell.DisplayName}}</span>
              <span class="material-icons text-gray dark:text-gray-lighter"
                *ngIf="cell === table.OrderByColumn">{{table.OrderDirection === 'asc' ? 'arrow_drop_up' :
                'arrow_drop_down'}}</span>
            </span>
          </td>
        </ng-container>
        <td [style.top]="offsetTranslate"
          class="sticky z-10 p-10 font-semibold uppercase bg-white text-2xs text-gray dark:bg-gray-darker dark:text-white whitespace-nowrap">
          <span class="flex items-center min-h-24 focus:outline-none">
            <span class="mr-5">Actions</span>
          </span>
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-container *cdkVirtualFor="let row of table.Body; let index=index;">
        <tr class="h-64 group accordion-parent" id="accountRow{{index}}">
          <td class="py-20 pl-20 pr-10 font-bold capitalize">
            <button mat-button class="icon-button is-primary is-inverted is-sm"
              [class]="row.ShowSleeves ? 'bg-gray-lighter' : ''" *ngIf="row.Sleeves.length"
              aria-label="Show/Hide Sleeves" (click)="row.ShowSleeves=!row.ShowSleeves">
              <span class="material-icons mi-18">{{row.ShowSleeves ? 'keyboard_arrow_down' :
                'keyboard_arrow_right'}}</span>
            </button>
            <span class="min-w-28" *ngIf="!row.Sleeves.length"></span>
          </td>
          <td class="p-10 py-20 font-bold capitalize">
            <ng-container *ngFor="let adv of row.Advisors; let lastAdv=last">{{adv.FirstName}} {{adv.LastName}}
              <ng-container *ngIf="!lastAdv">, </ng-container>
            </ng-container>
          </td>
          <td class="p-10 py-20 capitalize">
            <ng-container *ngFor="let c of row.Clients; let lastClient=last">{{c.LastName | titlecase}}, {{c.FirstName |
              titlecase}}<br />
            </ng-container>
          </td>
          <td class="p-10 py-20">{{row.Type}}</td>
          <td class="p-10 py-20">
            <div class="flex flex-col gap-y-2">
              <!-- flex on the td element orphans it from the table formatting. use sub element for specific formatting -->
              <ng-container *ngIf="row.IsInvestmentAccount; else IsInsuranceAccount">
                <span class="font-bold">{{row.Model || 'No Model'}}</span>
                <span *ngIf="row.TDSchwabAccountNumbers.length === 0">{{row.Custodian ? row.Custodian : 'No Custodian'}}{{row.AccountNumber ? ' - ' + row.AccountNumber
                  : ''}}
                </span>
                <div *ngIf="row.TDSchwabAccountNumbers.length" class="flex-row">
                  <div>
                    {{row.Custodian}} - {{row.AccountNumber}}
                  </div>
                  <div *ngIf="row.CustodianId === 1">
                    Charles Schwab - {{row.TDSchwabAccountNumbers[0].schwabAccountNumber}}
                  </div>
                  <div *ngIf="row.CustodianId === 2">
                    TD Ameritrade - {{row.TDSchwabAccountNumbers[0].tdAccountNumber}}
                  </div>
                </div>
              </ng-container>
              <ng-template #IsInsuranceAccount>
                <span class="font-bold">{{row.Product || 'No Product'}}</span>
                <span>{{row.Carrier ? row.Carrier : 'No Carrier'}}{{row.AccountNumber ? ' - ' + row.AccountNumber :
                  ''}}</span>
              </ng-template>
            </div>
          </td>
          <td class="p-10 py-20 font-bold">{{(row.Value | currency) || '---'}}</td>
          <td class="p-10 py-20">{{(row.OpenedDate | date: 'shortDate') || '---' }}</td>
          <td class="p-10 py-20">{{row.Fee}}</td>
          <td class="p-10 py-20">
            <div class="flex items-center">
              <span [ngClass]="{'text-green' : row.Status?.toLowerCase() === 'active'}">{{row.Status || '---'}}</span>
            </div>
          </td>
          <td class="p-10 py-20">            
            <button class="ml-auto button icon-button is-primary is-inverted" mat-button
              [matMenuTriggerFor]="accountMenu" *ngIf="row.InvestmentAccountId && row.Status !== 'Closed'">
              <span class="material-icons">more_horiz</span>
            </button>
            <mat-menu #accountMenu="matMenu">
              <div class="flex flex-col items-start gap-y-10">
                <ng-container *appShowIfOne="perm.ViewOrionElementRequests">
                  <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                    aria-label="Submit New Request" [matMenuTriggerFor]="openOrionMenu">
                    <span>Submit Service Request</span>
                  </button>
                  <mat-menu #openOrionMenu="matMenu">
                    <div class="flex flex-col items-start gap-y-10">                                       
                      <ng-container>
                        <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                          (click)="openOrionAgentSelectModal('ap_requests')" aria-label="Orion Advisor Portal - Contribution">
                          <span>Contribution</span>
                        </button>
                      </ng-container>
                      <ng-container>
                        <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                          (click)="openOrionAgentSelectModal('ap_requests')" aria-label="Orion Advisor Portal - Distribution">
                          <span>Distribution</span>
                        </button>
                      </ng-container>
                      <ng-container>
                        <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                          (click)="openAccountUpdateModal(row)" aria-label="Model Allocation / Trade">
                          <span>Model Allocation/Trade</span>
                        </button>
                      </ng-container>
                      <ng-container *appShowIfOne="perm.ViewOrionElementRequests">
                        <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                          (click)="openOrionAgentSelectModal('ap_requests')" aria-label="Orion Advisor Portal - Close Account">
                          <span>Close Account</span>
                        </button>
                      </ng-container>
                    </div>
                  </mat-menu>
                </ng-container>
                <ng-container *appHideIfOne="perm.ViewOrionElementRequests">
                  <ng-container
                    *appShowIfOne="[perm.SubmitContributionRequest, perm.SubmitDistributionRequest, perm.SubmitReallocationRequest, perm.SubmitAccountCloseRequest]">
                    <button class="flex items-center justify-start button is-primary is-lg is-inverted text-gray-darker"
                      mat-menu-item (click)="openAccountUpdateModal(row)" aria-label="Submit account request">
                      <span class="my-auto">Submit New Request</span>
                    </button>
                  </ng-container>
                </ng-container>            
                <ng-container *appShowIfAll="perm.SubmitMessageFile">
                  <button class="flex items-center justify-start button is-primary is-lg is-inverted text-gray-darker"
                    mat-menu-item (click)="openFileMessageUploadModal(row)" aria-label="Open uploads & messages modal">
                    <span class="my-auto">Upload Files & Messages</span>
                  </button>
                </ng-container>
              </div>
            </mat-menu>
          </td>
        </tr>
        <ng-container *ngIf="row.ShowSleeves">
          <tr class="accordion-child" [ngClass]="{'last': last}"
            *ngFor="let sleeve of row.Sleeves; let index = index; let last = last">
            <td class="py-20 pl-20"></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="py-5">{{sleeve.investmentAccountModel ?? 'No Model' + ' - ' + sleeve.accountNumber}}</td>
            <td>{{sleeve.currentValue | currency}}</td>
            <td></td>
            <td>{{sleeve.accountTotalFee | percent:'1.2'}}</td>
            <td>{{sleeve.investmentAccountStatus ? sleeve.investmentAccountStatus : '---'}}</td>
            <td></td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isLoading && !table.Body.length">
        <tr>
          <td colspan="100%" class="p-10 py-20">
            <span class="text-md">No accounts found. Try expanding your search criteria.</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>
<div class="flex-grow w-full loader-wrap" *ngIf="isLoading" @fadeIn>
  <div class="loader-sm is-blue"></div>
</div>