<table class="border-separate table-auto min-w-full">
    <thead>
        <td class="p-10 text-lg font-semibold uppercase text-gray">
            <span class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none">First Name</span>
        </td>
        <td class="p-10 text-lg font-semibold uppercase text-gray">
            <span class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none">Last Name</span>
        </td>
        <td class="p-10 text-lg font-semibold uppercase text-gray">
            <span class="flex items-center leading-6 cursor-pointer min-h-24 focus:outline-none">Number</span>
        </td>
    </thead>
    <tbody>
        <tr [class]="team.Class" class="bg-opacity-10 hover:bg-opacity-20 cursor-pointer" [routerLink]="'Team/' + player.TeamId + '/Player/' + player.Id"  *ngFor="let player of players">
            <td>
                <span class="flex items-center text-lg bg-opacity-25 cursor-pointer p-10 text-gray">{{player.FirstName}}</span>
            </td>
            <td>
                <span class="flex items-center text-lg bg-opacity-25 cursor-pointer p-10 text-gray">{{player.LastName}}</span>
            </td>
            <td>
                <span class="flex items-center text-lg bg-opacity-25 cursor-pointer p-10 text-gray">{{player.Number}}</span>
            </td>
        </tr>
    </tbody>
</table>