<a class="pt-8 text-sm font-semibold text-blue-dark cursor-pointer" (click)="showNewSleeve(false)"
    *ngIf="!this.newSleeve?.controls?.showAddNewSleeve?.value">
    ADD A NEW MODEL
</a>

<a class="pt-8 text-sm font-semibold text-blue-dark cursor-pointer ml-10" (click)="showNewSleeve(true)"
    *ngIf="!this.newSleeve?.controls?.showAddNewSleeve?.value && !customHoldingsSleeveExists()">
    ADD CUSTOM HOLDINGS
</a>

<div *ngIf="this.newSleeve?.controls?.showAddNewSleeve?.value"
    class="grid text-xs font-semibold uppercase text-gray mt-8 grid-cols-reallocation gap-x-10">
    <span>Model</span>
    <span>Account Fee</span>
    <span>Allocation Amount</span>
</div>
<form *ngIf="this.newSleeve?.controls?.showAddNewSleeve?.value" class="grid grid-cols-reallocation gap-x-10 mt-8"
    [formGroup]="newSleeve!" (ngSubmit)="addNewSleeve(true);">
    <div class="flex flex-none">
        <input type="text" matInput class="w-full input text-sm" placeholder="Select a Model" onfocus="this.select()"
            formControlName="searchTerm" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayModel" panelWidth="min-w-250"
            (optionSelected)="onChangeModelSearch($event)" ngDefaultControl>
            <mat-option *ngFor="let m of filteredModels | async" [value]="m" class="text-sm">
                {{m.name}}
            </mat-option>
        </mat-autocomplete>
    </div>
    <input currencyMask [options]="accountTotalFeeMaskOptions" formControlName="accountTotalFee" type="text"
        onfocus="this.select()" placeholder="% Fee" class="input text-sm min-w-72" />
    <div class="flex flex-none h-34" *ngIf="form?.value?.type === Dollar">
        <input currencyMask placeholder="$ Amount" type="text" min="1" step="any" onfocus="this.select()"
            class="rounded-r-none input flex-grow text-sm" formControlName="amount">
        <div
            class="flex items-center justify-center flex-none w-58 text-white border-solid rounded-r bg-gray border-gray-lighter">
            <label currencyMask *ngIf="initialAmount" formControlName="percentage" class="font-bold">
                {{(newSleeve!.value.percentage ? newSleeve!.value.percentage : 0) | number:'1.0-2'}}%</label>
        </div>
    </div>
    <div class="flex flex-none h-34" *ngIf="form?.value?.type === Percent">
        <input currencyMask [options]="sleevePercentageMaskOptions" placeholder="% Amount" type="text" min="1"
            onfocus="this.select()" step="any" class="rounded-r-none input flex-grow text-sm min-w-72"
            formControlName="percentage">
        <div
            class="flex items-center justify-center flex-none w-120 text-white border-solid rounded-r bg-gray border-gray-lighter">
            <label currencyMask *ngIf="initialAmount" formControlName="amount" class="font-bold">
                {{(newSleeve!.value.amount ? newSleeve!.value.amount : 0) | currency}}</label>
        </div>
    </div>
    <div class="flex flex-none">
        <!-- <button class="flex-none icon-button is-primary is-outlined" *ngIf="!this.newSleeve?.value?.isCustomHoldings"
            [ngClass]="{'animate-pulse bg-blue-dark text-white' : newSleeve?.valid}" mat-button
            [disabled]="!newSleeve?.valid" type="submit" matTooltip="Add new model">
            <mat-icon>add</mat-icon>
        </button> -->
        <button matTooltip="{{ !newSleeve?.valid ? 'Please Fill out the fee and amount.' : ''}}" type="submit"
            class="is-primary is-outlined bg-blue-lightest is-wide is-inverted relative"
            [ngClass]="{'animate-pulse bg-blue-dark text-white' : newSleeve?.valid}" [disabled]="!newSleeve?.valid">
            <span class=text-xs>{{ this.newSleeve?.value?.isCustomHoldings ? 'Enter Trades' : 'Add Model' }}</span>
        </button>        
        <button class="-ml-10" type="button" class="icon-button is-warning is-inverted" (click)="removeNewSleeve();">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
</form>