import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeIn } from 'src/app/shared/constants/animations';
import { Table } from 'src/app/shared/helpers/table.helpers';
import { AccountUpdateMainModalComponent } from '../../../account-request-workflows/modals/account-update-main-modal/account-update-main-modal.component';
import { FileMessageUploadModalComponent } from '../../../files-messages/modals/file-message-upload-modal/file-message-upload-modal.component';
import { Account } from '../../models/account.models';
import { AccountTableHeaders } from '../../models/accountTable.model';
import { Permission } from 'src/app/core/clients/generated/client';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { virtualScrolloffsetTranslate } from 'src/app/shared/helpers/virtual-scroll-offset-translate.helper';
import { OpenSsoAgentSelectModalComponent } from 'src/app/shared/modals/sso-agent-select-modal/sso-agent-select-modal.component';
import { FileMessageUploadModalModel } from '../../../files-messages/models/files-messages.model';


@Component({
  animations: [fadeIn],
  selector: 'app-accounts',
  templateUrl: './accounts-list.component.html',
})
export class AccountsListComponent implements OnChanges {
  constructor(
    private dialog: MatDialog,
  ) { }

  @ViewChild(CdkVirtualScrollViewport, { static: false }) public viewPort?: CdkVirtualScrollViewport;
  @Input() accounts: Account[] = [];
  isLoading = true;
  table: Table<Account> = new Table<Account>(AccountTableHeaders, 1, 'asc');
  perm = Permission;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['accounts']?.isFirstChange()) {
      this.table.setBody(this.accounts);
      this.isLoading = false;
    }
  }

  // This get the offset amount of the entire virtual scroll viewport from the top in pixel.
  // We use this to manually sticky the header back to the top position in the HTML since virtual scroll messes with sticky headers
  public get offsetTranslate(): string {
    return virtualScrolloffsetTranslate(this.viewPort);
  }

  openAccountUpdateModal(account: Account): void {
    this.dialog.open(AccountUpdateMainModalComponent, {
      height: '72rem',
      minHeight: '50rem',
      data: account.InvestmentAccountId ? account : null
    });
  }

  openFileMessageUploadModal(account: Account): void {
    //opening modal with account id, if defined
    const message : FileMessageUploadModalModel = {
      account: account.InvestmentAccountId ? account : undefined,
      onBehalfOfRequest: undefined,
    };
    this.dialog.open(FileMessageUploadModalComponent, {
      data: message
    });
  }

  openOrionAgentSelectModal(destination: string | null): void {
    this.dialog.open(OpenSsoAgentSelectModalComponent, {
      minHeight: '28rem',
      autoFocus: false,
      data: { BaseUrl: 'api/sso/orion/portal/' + destination + '?appearAsCrmAgentId=' }
    });
  }
}