import { CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';

/**
 * View documentation {@link https://www.npmjs.com/package/ngx-currency}
 */
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: true,
  /** Default to positive value for currency. Override locally */
  min: 0,
  inputMode: CurrencyMaskInputMode.NATURAL
};