import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Teams } from 'src/app/portal/features/example/constants/teams';
import { Team } from 'src/app/portal/features/example/models/team';

@Injectable({
  providedIn: 'root'
})
export class TeamsApiService {


  getTeams():Observable<Team[]>{
    // of function converts this call into an observable, which mimics the way angular handles http calls
    return of(Teams);
  }

  getTeam(Id:number):Observable<Team>{
    /* pipe is a way to manipulate data of a stream, you can nest multiple functions(referred to as operators in rxJS) withing the pipe operator.
    NOTE: The mapping, filtering, and tap operator don't happen until this method is Subsribed to */
    return this.getTeams()
      .pipe(
        map(teams => teams.filter(team => team.Id == Id)[0]),
        tap((teams) => console.log(teams))
      );
  }
}

// References
// https://rxjs.dev/guide/operators
// https://rxjs.dev/guide/observable