<form @fadeIn *ngIf="!isLoading" [formGroup]="planningDeskRequestFormGroup" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
  <header>
    <div class="m-10 flex flex-col">
      <h2 class="text-lg font-bold mr-20 leading-tight">Planning Desk Request - Step {{currentStep}}/{{pages.length}}</h2>
    </div>
    <button [disabled]="isSubmitting" mat-button
            class="m-10 ml-auto icon-button is-primary is-inverted"
            type="button" (click)="cancelRequest()">
            <mat-icon svgIcon="close"></mat-icon>
          </button>
  </header>
  <div class="flex-grow w-full loader-wrap" *ngIf="isSubmitting" @fadeIn>
    <div class="loader-sm is-blue"></div>
  </div>
  <section *ngIf="!isSubmitting && !isSubmitted" class="flex flex-col flex-grow h-full overflow-auto p-30">
    <app-general-info-step *ngIf="currentStep === accountUpdateStepEnum.step1" [parentForm]="generalInfoFormGroup" [advisorList]="advisorList"></app-general-info-step>
    <app-asset-details-step *ngIf="currentStep === accountUpdateStepEnum.step2" [parentForm]="assetDetailsFormGroup"></app-asset-details-step>
    <app-planning-desk-review-step *ngIf="currentStep === accountUpdateStepEnum.step3" [parentForm]="planningDeskRequestFormGroup"></app-planning-desk-review-step>
  </section>
  <section *ngIf="!isSubmitting && isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.planningDesk"></app-complete>
  </section>
  <footer *ngIf="!isComplete">
    <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            (click)="cancelRequest()"
            *ngIf="currentStep === accountUpdateStepEnum.step1">Cancel</button>
    <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            *ngIf="currentStep > accountUpdateStepEnum.step1"
            (click)="previousStep(); isSubmitted=false;">Back</button>
    <button [disabled]="isSubmitting" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
            (click)="nextStep(pages[currentStep - 1].formGroup)"
            *ngIf="(currentStep < pages.length)">Next</button>
    <button [disabled]="isSubmitting" type="submit" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
            *ngIf="currentStep === accountUpdateStepEnum.step3">{{isSubmitting ? "Submitting" : "Submit"}}</button>
  </footer>
</form>
<div @fadeIn class="flex-grow w-full loader-wrap" *ngIf="isLoading">
  <div class="loader-sm is-blue"></div>
</div>