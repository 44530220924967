<ng-container *ngIf="parentForm && requestType">
    <section *ngIf="!showCustomHoldings" class="flex-grow overflow-auto p-30" id="top-of-form">
        <div class="flex flex-col gap-y-15 mb-20">
            <app-allocation-input [requestType]="requestType"
                                  [investmentRequestFormService]="investmentRequestFormService"
                                  [customHoldingAllocations]="customHoldingAllocations" [form]="parentForm" [initialAmount]="initialAmount">
            </app-allocation-input>
        </div>
    </section>
    <app-custom-holdings [investmentRequestFormService]="investmentRequestFormService" [showCustomHoldingsFormControl]="showCustomHoldingsFormControl" *ngIf="showCustomHoldings" [requestType]="requestType"
                         [customHoldingsAmount]="customHoldingsAmount" [form]="customHoldings"></app-custom-holdings>
</ng-container>