import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { InvestmentRequestFormService } from '../../../../services/investment-request-form.service';
import { Buy, Sell } from '../../../constants/trade-action.constants';
import { RequestTypeEnum } from '../../../enums/account-update.enum';
import { AllocationCustomHoldingsFormGroup, AllocationCustomHoldingSleeveFormGroup } from '../../../models/allocation-form.models';

@Component({
  selector: 'app-custom-holdings',
  templateUrl: './custom-holdings.component.html',
})
export class CustomHoldingsComponent  {
  @Input() form?: AllocationCustomHoldingsFormGroup;
  @Input() customHoldingsAmount?: number;
  @Input() requestType?: RequestTypeEnum;
  @Input() showCustomHoldingsFormControl?: UntypedFormControl;
  @Input() investmentRequestFormService?: InvestmentRequestFormService;
  requestTypeEnum = RequestTypeEnum;
  newCustomHoldingForm:AllocationCustomHoldingSleeveFormGroup;

  constructor (
    private fb: UntypedFormBuilder
  ) {
    this.newCustomHoldingForm = this.fb.group({
      action: [null, Validators.required],
      stockSymbol: [ null, Validators.required],
      amount: [ null, Validators.required],
      type: [this.requestType == RequestTypeEnum.contribution ? Buy : this.requestType == RequestTypeEnum.distribution ? Sell : '', Validators.required]
    }) as AllocationCustomHoldingSleeveFormGroup;
  }

  done(): void{
    this.showCustomHoldingsFormControl?.setValue(false);
  }
}