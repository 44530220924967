import { Component, AfterViewInit, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { AccountClientModel, CloseAccountRequestDetailModel, CloseAccountRequestsClient, ContributionRequestDetailModel, ContributionRequestsClient, DistributionRequestDetailModel, DistributionRequestsClient, FileModel, OrionServiceRequestModel, OrionServiceRequestsClient, PlanningDeskAccountModel, PlanningDeskRequestDetailModel, PlanningDeskRequestsClient, ReallocationRequestDetailModel, ReallocationRequestsClient, RequestModel, SleeveModel, TradeModel } from 'src/app/core/clients/generated/client';
import { RequestTrackerTypeEnum } from '../../models/request-tracker-models';
import { fadeIn } from 'src/app/shared/constants/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-request-tracker-detail--modal',
  templateUrl: './request-tracker-detail-modal.component.html',
})
export class RequestTrackerDetailModalComponent implements OnInit, AfterViewInit {
  constructor(
    private reallocationRequestsApiService: ReallocationRequestsClient,
    private distributionRequestsApiService: DistributionRequestsClient,
    private closeAccountRequestsApiService: CloseAccountRequestsClient,
    private contributionRequestsApiService: ContributionRequestsClient,
    private planningDeskRequestsApiService: PlanningDeskRequestsClient,
    private orionServiceRequestsApiService: OrionServiceRequestsClient,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<RequestTrackerDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RequestModel,
  ) { }

  clients: AccountClientModel[] = [];
  clientTitle = RequestTrackerTypeEnum.Clients;
  details = new Map<string, unknown>();
  filePrefix = `api/${this.data.category.replace('-', '').replace(/\s+/g, '-').toLowerCase()}-requests/${this.data.investmentAccountModelRequestId
    || this.data.investmentAccountDistributionRequestId  || this.data.portfolioAnalysisRequestId || this.data.orionServiceRequestId}/files/` ;
  grossDistributionAmountTitle = RequestTrackerTypeEnum.GrossDistributionAmount;
  grossContributionAmountTitle = RequestTrackerTypeEnum.GrossContributionAmount;
  grossDistributionAmount?= 0.0;
  grossContributionAmount?= 0.0;
  grossSleeveAmount = 0.0;
  note = '';
  files: FileModel[] = [];
  tradeInstructions = '';
  sleeves: SleeveModel[] = [];
  trades: TradeModel[] = [];
  planningDeskAccounts: PlanningDeskAccountModel[] = [];
  sleevesTitle = RequestTrackerTypeEnum.Sleeves;
  tradesTitle = RequestTrackerTypeEnum.Trades;
  accountsTitle = RequestTrackerTypeEnum.Accounts;
  requestTrackerTypeEnum = RequestTrackerTypeEnum;
  statusObj = {
    Show: false,
    Status: '',
    StatusDate: '',
    StatusNote: '',
    StatusId: 0,
  };

  ngOnInit(): void {
    this.note = '';
    this.tradeInstructions = '';
    this.clients = [];
    this.details.clear();
    if (this.data.category == RequestTrackerTypeEnum.Reallocation) {
      if (this.data.investmentAccountModelRequestId != null)
        this.reallocationRequestsApiService.getReallocationRequestDetail(this.data.investmentAccountModelRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category == RequestTrackerTypeEnum.CloseAccount) {
      if (this.data.investmentAccountDistributionRequestId != null)
        this.closeAccountRequestsApiService.getCloseAccountRequestDetail(this.data.investmentAccountDistributionRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category == RequestTrackerTypeEnum.Distribution) {
      if (this.data.investmentAccountDistributionRequestId != null)
        this.distributionRequestsApiService.getDistributionRequestDetail(this.data.investmentAccountDistributionRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category == RequestTrackerTypeEnum.Contribution) {
      if (this.data.investmentAccountDistributionRequestId != null)
        this.contributionRequestsApiService.getContributionRequestDetail(this.data.investmentAccountDistributionRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category == RequestTrackerTypeEnum.PlanningDesk) {
      if (this.data.portfolioAnalysisRequestId != null)
        this.planningDeskRequestsApiService.getPlanningDeskRequestDetail(this.data.portfolioAnalysisRequestId).subscribe({
          next: (details) => {
            this.setPlanningDeskDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category == RequestTrackerTypeEnum.OrionServiceRequest) {
      if (this.data.orionServiceRequestId != null)
        this.orionServiceRequestsApiService.getOrionServiceRequestDetail(this.data.orionServiceRequestId).subscribe({
          next: (details) => {
            this.setOrionServiceRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    }
  }

  ngAfterViewInit(): void {
    const width = '66rem';
    const height = '84rem';
    this.dialogRef.updateSize(width, height);
  }

  getTotalSleeves(): void {
    this.sleeves.forEach(element => {
      this.grossSleeveAmount += element.amount;
    });
  }

  closeDialogAndSendMessage(): void {
    this.dialogRef.close({openMessage: true});
  }

  setRequestDetailObject(detail: ContributionRequestDetailModel | DistributionRequestDetailModel | CloseAccountRequestDetailModel | ReallocationRequestDetailModel ): void {
    console.log(detail);
    this.files = detail.files;
    this.statusObj.Status = detail.status;
    this.statusObj.StatusDate = new Date(detail.statusDate).toLocaleDateString();
    this.statusObj.StatusNote = detail.statusNote;
    this.statusObj.StatusId = detail.statusId;

    this.details.set('Clients', '');
    this.details.set('Account #', detail.investmentAccountNumber);
    this.details.set('Account Type', detail.investmentAccountType);
    this.details.set('Submitted On', new Date(detail.submittedDate).toLocaleDateString());

    if (this.data.category.includes(RequestTrackerTypeEnum.Reallocation)) {
      const m = detail as ReallocationRequestDetailModel;
      this.sleeves = m.sleeves;
      this.tradeInstructions = m.tradeInstructions;
      this.trades = m.trades;
    } else if (this.data.category.includes(RequestTrackerTypeEnum.Distribution)) {
      const d = detail as DistributionRequestDetailModel;
      this.details.set('Federal Tax Withholding', d.federalTax ? d.federalTax + '%' : 'N/A');
      this.details.set('State Tax Withholding', d.stateTax ? d.stateTax + '%' : 'N/A');
      this.details.set('Frequency', d.frequency);
      this.details.set('Method', d.method);
      this.details.set('Gross Distribution Amount', '');
      this.details.set('Begins On', d.beginsOn ? new Date(d.beginsOn).toLocaleDateString() : 'N/A');
      this.sleeves = d.sleeves;
      this.tradeInstructions = d.tradeInstructions;
      this.trades = d.trades;
      this.grossDistributionAmount = d.grossAmount;
    } else if (this.data.category.includes(RequestTrackerTypeEnum.CloseAccount)) {
      const d = detail as CloseAccountRequestDetailModel;
      this.details.set('Federal Tax', d.federalTax ? d.federalTax + '%' : 'N/A');
      this.details.set('State Tax', d.stateTax ? d.stateTax + '%' : 'N/A');
      this.details.set('Distribution Method', d.method);
      this.details.set('Bill As Of Close Date', d.isCloseDateBilled ? 'Yes' : 'No');
    } else if (this.data.category.includes(RequestTrackerTypeEnum.Contribution)) {
      const d = detail as ContributionRequestDetailModel;
      this.details.set('Frequency', d.frequency);
      this.details.set('Method', d.method);
      this.details.set('Gross Contribution Amount', '');
      this.details.set('Begins On', d.beginsOn ? new Date(d.beginsOn).toLocaleDateString() : 'N/A');
      this.sleeves = d.sleeves;
      this.tradeInstructions = d.tradeInstructions;
      this.grossContributionAmount = d.grossAmount;
    }
    this.clients = detail.clients;
    this.note = detail.notes;
    if (this.sleeves.length > 0) {
      this.details.set('Allocations', '');
      this.getTotalSleeves();
    }
    if (this.trades.length > 0) {
      this.details.set('Trades', '');
    }
  }

  setPlanningDeskDetailObject(detail: PlanningDeskRequestDetailModel ): void {
    console.log(detail);
    this.files = detail.files;
    this.statusObj.Status = detail.status;
    this.statusObj.StatusDate =detail.statusDate ? new Date(detail.statusDate).toLocaleDateString() : 'N/A';
    this.statusObj.StatusId = detail.statusId;
    this.statusObj.StatusNote = detail.statusNote;

    this.details.set('Client', detail.client);
    this.details.set('Request Type', detail.requestType);
    this.details.set('Next Appointment', detail.nextAppointment ? new Date(detail.nextAppointment).toLocaleDateString() : 'N/A');
    this.details.set('Submitted On', new Date(detail.submittedDate).toLocaleDateString());
    this.details.set('Updated On', detail.statusDate ? new Date(detail.statusDate).toLocaleDateString() : 'N/A');
    this.details.set('Status', detail.status);

    this.note = detail.notes;
    this.planningDeskAccounts = detail.accounts;
    if (this.planningDeskAccounts.length > 0) {
      this.details.set('Accounts', '');
      this.planningDeskAccounts.forEach(element => {
        element.clientFee = element.clientFee / 100;
      });
    }
  }

  setOrionServiceRequestDetailObject(detail: OrionServiceRequestModel): void {
    console.log(detail);
    this.files = detail.files;
    this.statusObj.Status = detail.status;
    this.statusObj.StatusDate = new Date(detail.statusDate).toLocaleDateString();
    this.statusObj.StatusNote = detail.statusNote;
    this.statusObj.StatusId = detail.statusId;

    this.details.set('Clients', '');
    this.details.set('Account #', detail.investmentAccountNumber);
    this.details.set('Account Type', detail.investmentAccountType);
    this.details.set('Submitted On', new Date(detail.submittedDate).toLocaleDateString());
    this.clients = detail.clients;

    if (detail.requestDetails.length) {

      const requestType = detail.requestDetails[0].requestType;
      this.details.set(requestType + ' Type', detail.requestDetails[0].requestSubType);

      if (detail.requestDetails[0].contributionAmount != null) {
        this.details.set('Gross Contribution Amount', '');
        this.grossContributionAmount = detail.requestDetails[0].contributionAmount;
      }

      if (detail.requestDetails[0].distributionAmount != null) {
        this.details.set('Gross Distribution Amount', '');
        this.grossDistributionAmount = detail.requestDetails[0].distributionAmount;
      }

      if (detail.requestDetails[0].setupOption != null) {
        this.details.set('Setup Option', detail.requestDetails[0].setupOption);
      }

      if (detail.requestDetails[0].frequency != null) {
        this.details.set('Frequency', detail.requestDetails[0].frequency);
      }

      if (detail.requestDetails[0].startDate != null) {
        this.details.set('Start Date', new Date(detail.requestDetails[0].startDate).toLocaleDateString());
      }

      if (detail.requestComments.length) {
        detail.requestComments.forEach(comment => {
          this.note += comment.note + '\r\n\r\n';
        });
      }
    }
  }

  sortNull(): number {
    return 0;
  }
}
