<div class="flex flex-row justify-between gap-x-10 text-sm" *ngFor="let allocation of customHoldingAllocations?.controls; let i = index">
    <ng-container *ngIf="!allocation.value.action.isStock">
        <span>{{allocation.value.action.name}} </span>
    </ng-container>
    <span>{{allocation.value.stockSymbol}}</span>
    <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
    <span [ngClass]="{'text-green' : allocation.value.type === Buy, 'text-orange': allocation.value.type === Sell}">
        {{allocation.value.action.tradeType}}
        {{allocation.value.action.allocationAmountType === Share ? allocation.value.amount : (allocation.value.amount | currency) }}
        <span *ngIf="allocation.value.action.allocationAmountType === Share"> Shares</span>
    </span>
</div>