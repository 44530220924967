<section class="flex flex-col p-10 gap-y-10">
  <h2 class="p-20 pb-0 text-lg font-bold" *ngIf="model?.VideoAssets?.length">Videos</h2>
  <section class="flex flex-wrap w-full" *ngIf="model?.VideoAssets?.length">
    <div *ngFor="let asset of model?.VideoAssets" class="w-1/2 p-20 lg:w-1/4 md:w-1/3">
      <app-development-center-wistia-media class="flex flex-col justify-center" [model]="asset"></app-development-center-wistia-media>
    </div>
  </section>
  <h2 class="p-20 pb-0 text-lg font-bold" *ngIf="model?.FileAssets?.length">Downloads</h2>
  <section class="flex flex-wrap w-full" *ngIf="model?.FileAssets?.length">   
    <div *ngFor="let asset of model?.FileAssets; let index = index" class="w-1/2 p-20 lg:w-1/4 md:w-1/3">
      <app-development-center-wistia-media class="min-h-40" [model]="asset"></app-development-center-wistia-media>
    </div>
  </section>
</section>