<form @fadeIn *ngIf="account && workflow" [formGroup]="closeAccountFormGroup" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
  <app-account-request-modal-header #header [title]="workflow.title" [account]="account" (closeRequested)="closeRequest()"></app-account-request-modal-header>
  <div class="flex-grow w-full loader-wrap" *ngIf="isSubmitting" @fadeIn>
    <div class="loader-sm is-blue"></div>
  </div>
  <section *ngIf="!isSubmitting && !isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <div class="flex flex-col w-full" *ngIf="currentStep === accountUpdateStepEnum.step1">
      <div class="flex flex-col overflow-auto p-30 gap-y-30">
        <div class="flex flex-col gap-y-30" [formGroup]="closeAccountFormGroup">
          <div class="flex flex-col gap-y-10">
            <label class="text-base font-bold text-gray-dark required-label">Reason for closing account / additional notes</label>
            <textarea class="textarea" rows="2" placeholder="Please enter a reason for closing your account and any additional notes" formControlName="reason"></textarea>
            <span *ngIf="closeAccountFormGroup.controls.reason.touched && closeAccountFormGroup.controls.reason.errors" class="w-full text-xs text-red">
              <span *ngIf="closeAccountFormGroup.controls.reason.hasError('required')">This field is required.</span>
            </span>
          </div>         
          <div class="flex flex-col gap-y-10">
            <label class="text-base font-bold text-gray-dark required-label -mb-5">Should the account be billed upon closing?</label>
            <mat-checkbox class="pl-5 checkbox" formControlName="billAccountOnClosing">
              <span class="pl-5">Bill account as of close date</span>
            </mat-checkbox>
          </div>          
        </div>
      </div>
      <app-tax-options class="pl-30 pr-30" [taxFormGroup]="closeAccountFormGroup"></app-tax-options>
      <app-delivery-methods [parentForm]="closeAccountFormGroup.controls.deliveryOptions" [requestType]="requestTypeEnum.closeAccount"></app-delivery-methods>
    </div>
    <div class="flex flex-col w-full" *ngIf="currentStep === accountUpdateStepEnum.step2">
      <div class="flex flex-col overflow-auto p-30 gap-y-30">
        <app-close-account-review-step [parentForm]="closeAccountFormGroup"></app-close-account-review-step>
      </div>
    </div>   
  </section>
  <section *ngIf="!isSubmitting && isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.closeAccount"></app-complete>
  </section>
  <footer *ngIf="!isComplete">
    <button [disabled]="isSubmitting" *ngIf="!isSubmitted && currentStep === accountUpdateStepEnum.step1" type="button" mat-button class="flex justify-center m-10 button is-primary is-inverted is-lg is-outlined"
            (click)="cancelRequest()">Back</button>
    <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 button is-primary is-inverted is-lg is-outlined"
            *ngIf="!isSubmitted && (currentStep > accountUpdateStepEnum.step1)"
            (click)="previousStep();">Back</button>
    <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 ml-auto button is-primary is-lg"
            (click)="nextStep(pages[currentStep - 1].formGroup)"
            *ngIf="!isSubmitted && (currentStep < pages.length)">Next</button>  
    <button [disabled]="isSubmitting" type="submit" mat-button class="flex justify-center m-10 ml-auto button is-primary is-lg" *ngIf="!isSubmitted && (currentStep === pages.length)">
            {{isSubmitting ? "Submitting" : "Submit"}}</button>
  </footer>
</form>