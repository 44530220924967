<ng-container *ngIf="parentForm">
  <section class="grid grid-cols-2 gap-x-20 gap-y-30" [formGroup]="parentForm">
    <p class="col-span-2 text-lg">Enter a few details to complete your planning desk request.</p>
    <div class="flex flex-col col-span-2 xs:col-span-1" *ngIf="advisorList">
      <label class="mb-20 font-bold">Who is initiating this request? <sup class="text-red">*</sup></label>
      <mat-select formControlName="agent" class="select" placeholder="Please select an Advisor">
        <mat-option *ngFor="let agent of advisorList" [value]="agent">{{agent.firstName}} {{agent.lastName}}</mat-option>
      </mat-select>
      <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red" *ngIf="parentForm.controls.agent.errors && parentForm.controls.agent.touched">
        <span *ngIf="parentForm.controls.agent.hasError('required')">This field is required.</span>
      </span>
    </div>

    <div class="flex flex-col col-span-2 xs:col-span-1">
      <label class="mb-20 font-bold">What is the client's name? <sup class="text-red">*</sup></label>
      <input type="text" class="input" formControlName="client" placeholder="Please enter client's full name" />
      <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red" *ngIf="parentForm.controls.client.errors && parentForm.controls.client.touched">
        <span *ngIf="parentForm.controls.client.hasError('required')">This field is required.</span>
      </span>
    </div>

    <div class="flex flex-col col-span-2 xs:col-span-1">
      <label class="mb-20 font-bold">When is the next appointment? <sup class="text-red">*</sup></label>
      <div class="datepicker-input-container">
        <input [matDatepicker]="picker" class="datepicker-input" formControlName="nextAppointmentDate" (click)="picker.open()" readonly
               placeholder="mm/dd/yyyy"  [min]="minStartDate">
        <mat-icon class="datepicker-icon" svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
        <mat-datepicker #picker color="accent"></mat-datepicker>
      </div>
      <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red" *ngIf="parentForm.controls.nextAppointmentDate.errors && parentForm.controls.nextAppointmentDate.touched">
        <span *ngIf="parentForm.controls.nextAppointmentDate.hasError('required')">This field is required.</span>
      </span>
    </div>

    <div class="flex flex-col col-span-2">
      <label class="mb-20 font-bold">What type of request is this? <sup class="text-red">*</sup></label>
      <mat-radio-group formControlName="requestType" class="flex flex-wrap gap-x-30 gap-y-10">
        <mat-radio-button class="flex-none" *ngFor="let option of planningDeskRequestType" [value]="option">{{option.name}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="flex flex-col col-span-2" *ngIf="parentForm.controls.recommendationType?.enabled">
      <label class="mb-20 font-bold">Who is providing the recommendation? <sup class="text-red">*</sup></label>
      <mat-button-toggle-group formControlName="recommendationType" class="mr-auto button-toggle-group">
        <ng-container *ngFor="let option of recommendationType">
          <mat-button-toggle [value]="option">{{option.name}}</mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </div>
    <div class="flex flex-col col-span-2">
      <label class="mb-20 font-bold">Tell us what you would like to see <sup *ngIf="parentForm.controls.notes.hasError('required')" class="text-red">*</sup></label>
      <textarea formControlName="notes" rows="6" class="textarea" placeholder="Enter your notes here"></textarea>
      <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red" *ngIf="parentForm.controls.notes.errors && parentForm.controls.notes.touched">
        <span *ngIf="parentForm.controls.notes.hasError('required')">This field is required.</span>
      </span>
    </div>
  </section>

</ng-container>