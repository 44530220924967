import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { API_BASE_URL } from './generated/client';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useFactory: (): string => '/api',
    }
  ],
})
export class ClientsModule { }
