<section class="flex flex-col flex-grow gap-y-20" *ngIf="model?.Asset?.mediaType === 'Video'">  
  <div class="w-full overflow-hidden rounded wistia_responsive_wrapper h-200">
    <span class="wistia_embed wistia_async_{{model?.Asset?.assetId}} 
    popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only h-full w-full inline-block">
    </span>
  </div>
  <section class="flex justify-center flex-none w-full">
    <span class="text-xs font-bold text-center">{{model?.Description}}</span>
  </section>
</section>
<section class="flex justify-self-start" *ngIf="model?.Asset?.mediaType === 'File'">
  <span class="transition duration-150 rounded cursor-pointer text-blue-dark hover:bg-blue-lightest">
    <a class="flex items-center no-underline rounded cursor-pointer text-blue-dark hover:no-underline focus:no-underline"
      [href]="'api/wistia/assets/' + model?.Asset?.assetId" target="_blank"
      [attr.aria-label]="'Open ' + model?.Asset?.name" rel="noopener">
      <div class="flex items-center justify-center flex-shrink-0 w-30 h-30">
        <mat-icon class="mi-18 text-gray">description</mat-icon>
      </div>
      <span class="text-sm font-bold leading-normal">{{model?.Asset?.name}}</span>
    </a>
  </span>
</section>