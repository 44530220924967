<form @fadeIn *ngIf="account && workflow" [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
  <app-account-request-modal-header #header [title]="workflow.title" [account]="account" [formService]="distributionFormService" (closeRequested)="closeRequest()"></app-account-request-modal-header>
  <div class="flex-grow w-full loader-wrap" *ngIf="isSubmitting" @fadeIn>
    <div class="loader-sm is-blue"></div>
  </div>
  <section *ngIf="!isSubmitting && !isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-distribute-funds *ngIf="distributionFormService.currentStep === accountUpdateStepEnum.step1"
                          [parentForm]="form.controls.amount"
                          [account]="account"></app-distribute-funds>
    <app-allocation-step class="h-full" *ngIf="distributionFormService.currentStep === accountUpdateStepEnum.step2" [investmentRequestFormService]="distributionFormService" [parentForm]="form.controls.allocationConfiguration" [initialAmount]="distributionFormService.initialAmount" [requestType]="requestTypeEnum.distribution"></app-allocation-step>
    <app-delivery-methods *ngIf="distributionFormService.currentStep === accountUpdateStepEnum.step3" [parentForm]="form.controls.deliveryOptions" [requestType]="requestTypeEnum.distribution"></app-delivery-methods>
    <app-review-step *ngIf="distributionFormService.currentStep === accountUpdateStepEnum.step4" [investmentRequestFormService]="distributionFormService" [requestType]="requestTypeEnum.distribution"></app-review-step>
   
  </section>
  <section *ngIf="!isSubmitting && isSubmitted" class="flex flex-col flex-grow h-full overflow-auto">
    <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.distribution"></app-complete>
  </section>
  <footer *ngIf="!showCustomHoldings && !isComplete" >
    <button [disabled]="isSubmitting" type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            (click)="cancelRequest()" *ngIf="distributionFormService.currentStep === accountUpdateStepEnum.step1">Back</button>
    <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center" *ngIf="(distributionFormService.currentStep > accountUpdateStepEnum.step1)"
            (click)="distributionFormService.goToPreviousStep(); isSubmitted=false;">Back</button>
    <button [disabled]="isSubmitting" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
            *ngIf="(distributionFormService.currentStep <= (pages.length - 1))" (click)="distributionFormService.goToNextStep(pages[distributionFormService.currentStep - 1].formGroup, pages.length)">Next</button>
    <button [disabled]="isSubmitting" type="submit" class="m-10 ml-auto button is-primary is-lg flex justify-center" *ngIf="distributionFormService.currentStep === accountUpdateStepEnum.step4">{{isSubmitting ? "Submitting" : "Submit"}}</button>
  </footer>
</form>