<div id="requestTrackerDetailModal" class="flex flex-col w-full h-full ">
  <section class="flex items-center flex-none p-10 border-b border-solid md:p-20 border-gray-lighter">
    <h2 class="m-10 mr-20 text-lg font-bold">{{data.category}} {{data.subCategory !== null ? ' - ' + data.subCategory :
      ''}} Request Details</h2>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" tabindex="-1" type="button"
      mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </section>
  <section class="flex flex-col overflow-auto p-30 gap-y-20">
    <div class="flex flex-col gap-y-5" *ngIf="data.category !== requestTrackerTypeEnum.PlanningDesk">
      <h2 class="font-bold text-gray-dark" [textContent]="data.investmentAccountModel || 'No Model'"></h2>
      <span class="text-gray">{{data.custodian}}{{data.investmentAccountNumber ? ' - ' + data.investmentAccountNumber :
        ''}}</span>
    </div>
    <div class="flex flex-col gap-y-5" *ngIf="statusObj.Status">
      <div class="flex justify-between border-dotted border-gray-light">
        <span class="text-gray-dark">Status:</span>
        <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
        <div class="font-bold text-gray-dark flex-row">
          <span [ngStyle]="{'color': 
          statusObj.StatusId === 4 ? '#D71D00' : 
          statusObj.StatusId === 5 ? '#127161' : 
          statusObj.StatusId === 6 ? '#FFAF3D' : 
          statusObj.StatusId === 7 ? '#D71D00' : 
          statusObj.StatusId === 8 ? '#D71D00' : 
          statusObj.StatusId === 9 ? '#D71D00' : 
          ''}"> {{ statusObj.Status }}</span> - <span>{{ statusObj.StatusDate }}</span>
        </div>
      </div>
      <div *ngIf="statusObj.StatusNote" class="flex flex-col p-5 mt-5 rounded bg-gray-lightest">
        <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{ statusObj.StatusNote }}</span>
      </div>
      <div class="flex justify-center mt-10">
        <button *ngIf="statusObj.StatusId !== 5" matTooltip="Send a new message with files" type=""
          class="is-primary is-outlined bg-blue-lightest is-wide is-inverted relative"
          (click)="closeDialogAndSendMessage();">
          <span class="text-xs text-blue-dark"> Send New Message</span>
        </button>
      </div>
    </div>
    <span class="flex-none divider-horizontal"></span>
    <div class="flex flex-col gap-y-10">
      <span class="text-base font-bold">Request Notes:</span>
      <div *ngIf="note?.length" class="flex flex-col p-5 rounded bg-gray-lightest">
        <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{note}}</span>
      </div>
      <em *ngIf="!note?.length" class="text-sm text-gray-light">No notes</em>
    </div>
    <div *ngIf="files.length">
      <h2 class="mb-10 text-base font-bold text-gray-dark">File Attachments</h2>
      <div class="grid grid-cols-1 gap-10 sm:grid-cols-2">
        <ng-container *ngFor="let asset of files">
          <span
            class="flex items-center w-full transition duration-150 rounded cursor-pointer text-blue-dark hover:bg-blue-lightest">
            <a class="flex items-center w-full no-underline rounded cursor-pointer text-blue-dark hover:no-underline focus:no-underline"
              [href]="filePrefix + asset.fileID" target="_blank" [attr.aria-label]="'Open ' + asset.fileName">
              <div class="flex items-center justify-center flex-shrink-0 w-48 h-48">
                <mat-icon class="mi-18 text-blue-dark">description</mat-icon>
              </div>
              <span class="text-sm font-bold leading-normal">{{asset.fileName}}</span>
            </a>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="flex-col py-2" *ngFor="let detail of details | keyvalue: sortNull">
        <div class="flex justify-between border-dotted border-gray-light">
          <div class=" text-gray-dark"
            [ngClass]="{'font-bold mt-10': detail.key === sleevesTitle || detail.key === accountsTitle  || detail.key === tradesTitle}">
            {{detail.key}}</div>
          <div class="flex-grow mx-10 border-b border-dotted border-gray-light"
            *ngIf="detail.key !== sleevesTitle && detail.key !== accountsTitle && detail.key !== tradesTitle"></div>
          <div class="font-bold text-gray-dark"
            *ngIf="detail.key !== clientTitle && detail.key !== sleevesTitle && detail.key !== accountsTitle  && detail.key !== tradesTitle">
            {{detail.value}} </div>       
          <div class="font-bold text-gray-dark" *ngIf="detail.key === grossContributionAmountTitle">{{grossContributionAmount |
            currency}}</div>
          <div class="font-bold text-gray-dark" *ngIf="detail.key === grossDistributionAmountTitle">{{grossDistributionAmount |
            currency}}</div>
          <div *ngFor="let client of clients; let lastClient=last" class="font-bold text-gray-dark">
            <span *ngIf="detail.key === clientTitle">{{client.firstName}} {{client.lastName}}<span
                *ngIf="!lastClient">,&nbsp;</span></span>
          </div>
        </div>
        <div class="flex flex-col w-full" *ngIf="detail.key === accountsTitle">
          <div class="flex-col py-2 ml-20" *ngFor="let account of planningDeskAccounts">
            <div class="flex justify-between border-dotted border-gray-light">
              <span class="text-gray-dark">Account Type</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{account.investmentAccountType}} </span>
            </div>
            <div class="flex justify-between border-dotted ml-15 border-gray-light">
              <span class="text-gray-dark">Current Account Value</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{account.currentAccountValue | currency}} </span>
            </div>
            <div class="flex justify-between border-dotted ml-15 border-gray-light">
              <span class="text-gray-dark">Client Fee</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{account.clientFee | percent: '1.0-3'}} </span>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full" *ngIf="detail.key === sleevesTitle">
          <div class="flex-col py-2 ml-20 pt-5" *ngFor="let sleeve of sleeves">
            <div class="flex justify-between border-dotted border-gray-light">
              <span class=" text-gray-dark">Account Model</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{sleeve.investmentAccountModel}} </span>
            </div>
            <div class="flex justify-between border-dotted ml-15 border-gray-light">
              <span class=" text-gray-dark">Amount</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{sleeve.amount | currency}}</span>
            </div>
            <div class="flex justify-between border-dotted ml-15 border-gray-light"
              *ngIf="data.category === requestTrackerTypeEnum.Reallocation">
              <span class=" text-gray-dark">Total Account Fee</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{sleeve.accountTotalFee | percent: '1.0-3'}} </span>
            </div>
            <div class="flex justify-between border-dotted ml-15 border-gray-light">
              <span class=" text-gray-dark">Percentage</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{(sleeve.amount / grossSleeveAmount) | percent: '1.2'}}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full" *ngIf="detail.key === tradesTitle">
          <div class="flex flex-col py-5 gap-y-10" *ngIf="tradeInstructions?.length">
            <div class="flex flex-col p-5 rounded bg-gray-lightest">
              <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{tradeInstructions}}</span>
            </div>
          </div>
          <div class="flex-col py-2 ml-20 pt-5" *ngFor="let trade of trades">
            <div class="flex justify-between border-dotted border-gray-light">
              <span class=" text-gray-dark">Instruction</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{trade.instruction}}</span>
            </div>
            <div class="flex justify-between border-dotted ml-15 border-gray-light"
              *ngIf="trade.instruction.toLowerCase().indexOf('all') === -1">
              <span class=" text-gray-dark">Amount</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{ trade.instruction.toLowerCase().indexOf('dollars') !== -1 ?
                (trade.amount | currency) : trade.amount
                }} </span>
            </div>
            <div class="flex justify-between border-dotted ml-15 border-gray-light">
              <span class=" text-gray-dark">Symbol</span>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <span class="font-bold text-gray-dark">{{trade.symbol}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>