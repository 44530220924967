<ng-container *ngIf="parentForm && requestType">
  <form [formGroup]="parentForm" class="flex flex-col w-full">
    <section class="flex flex-col overflow-auto p-30 gap-y-30">

      <!-- Method -->
      <!-- Required field -->
      <div class="flex flex-col gap-y-10">
        <div class="flex flex-row items-center gap-x-5">
          <label class="text-base font-bold text-gray-dark required-label">Please choose a
            {{requestTypeLabel}} method</label>
          <mat-icon *ngIf="requestType === requestTypeEnum.contribution" class="mi-18 text-blue-light" svgIcon="information" matTooltip="Only ACH is supported at this time"
                    matTooltipPosition="after"
                    matTooltipClass="text-sm border rounded-t rounded-b border-blue-lighter bg-blue-lightest text-gray-dark">
          </mat-icon>
        </div>
        <div class="flex flex-col gap-y-5">
          <mat-select formControlName="deliveryMethod"
                      (selectionChange)="updateDeliveryMethod(parentForm.value.deliveryMethod)" class="select" required>
            <mat-option *ngFor="let option of deliveryMethodList" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
          <span *ngIf="parentForm.controls.deliveryMethod.touched && parentForm.controls.deliveryMethod.errors"
                class="w-full text-xs -mt-15 text-red">
            <span *ngIf="parentForm.controls.deliveryMethod.hasError('required')">This field is required.</span>
          </span>
        </div>
        <mat-checkbox *ngIf="parentForm.controls.isOvernightCheckDelivery?.enabled" formControlName="isOvernightCheckDelivery">Overnight Delivery?</mat-checkbox>
        <div class="w-1/2 flex flex-col gap-y-5" *ngIf="parentForm.controls.wireDeliveryOptionId?.enabled">
          <mat-select class="select" placeholder="Choose whom to transfer to" formControlName="wireDeliveryOptionId">
            <mat-option *ngFor="let option of transferRecipientList" [value]="option.value">{{option.name}}</mat-option>
          </mat-select>
          <span *ngIf="parentForm.controls.wireDeliveryOptionId.touched && parentForm.controls.wireDeliveryOptionId.errors"
                class="w-full text-xs text-red">
            <span *ngIf="parentForm.controls.wireDeliveryOptionId.hasError('required')">This field is required.</span>
          </span>
        </div>
        <div *ngIf="parentForm.value.wireDeliveryOptionId === transferRecipientEnum.thirdParty" class="flex flex-row gap-x-10">
          <mat-icon class="mi-18 text-blue-light flex-shrink-0" svgIcon="information"></mat-icon>
          <span class="text-xs">Third party wire transfers require an applicable <a target="_blank" href="/api/wistia/download?form=tdwire">TD Ameritrade</a> or <a target="_blank" href="/api/wistia/download?form=schwabwire">Schwab</a> wire transfer instructions form that is signed by the client. Please download and attach the applicable form with client signature.</span>
        </div>
        <div class="w-1/2 flex flex-col gap-y-5" *ngIf="parentForm.controls.journalCashRecipientAccountNumber.enabled">
          <input class="input" formControlName="journalCashRecipientAccountNumber" placeholder="Enter account number" />
          <span *ngIf="parentForm.controls.journalCashRecipientAccountNumber.touched && parentForm.controls.journalCashRecipientAccountNumber.errors" class="w-full text-xs text-red">
            <span *ngIf="parentForm.controls.journalCashRecipientAccountNumber.hasError('required')">This field is required.</span>
          </span>
        </div>
      </div>

      <!-- Frequency -->
      <!-- Required field -->
      <div class="flex flex-col gap-y-10" *ngIf="requestType !== requestTypeEnum.closeAccount" >
        <label class="text-base font-bold text-gray-dark required-label" for="trade-frequency">How often is the {{requestTypeLabel}} made?</label>
        <div class="flex flex-col gap-y-10">
          <mat-select formControlName="tradeFrequency" (selectionChange)="updateDeliveryFrequency(parentForm.value.tradeFrequency)" class="select" required>
            <mat-option *ngFor="let option of tradeFrequencyList" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
          <span *ngIf="parentForm.controls.tradeFrequency.touched && parentForm.controls.tradeFrequency.errors"
                class="w-full text-xs text-red">
            <span *ngIf="parentForm.controls.tradeFrequency.hasError('required')">This field is
              required.</span>
          </span>
        </div>
      </div>

      <!-- Date to initiate -->
      <div *ngIf="parentForm.controls.initiationDate?.enabled" class="flex flex-col gap-y-10">
        <label class="text-base font-bold text-gray-dark required-label">When should
          the {{requestTypeLabel}} initiate?</label>
        <div class="datepicker-input-container">
          <input [matDatepicker]="picker" class="datepicker-input" [min]="minDate" formControlName="initiationDate" (click)="picker.open()" readonly
                 placeholder="mm/dd/yyyy">
          <mat-icon class="datepicker-icon" svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
          <mat-datepicker #picker color="accent"></mat-datepicker>
        </div>
        <span *ngIf="parentForm?.controls?.initiationDate?.touched"
              class="w-full text-xs -mt-15 text-red">
          <span *ngIf="parentForm.controls.initiationDate.hasError('required')">This field is required.</span>
        </span>
      </div>

      <!-- File upload -->
      <!-- Optional field -->
      <div class="flex flex-col gap-y-10">
        <label class="text-base font-bold text-gray-dark required-label" for="contribution-uploads">Please upload any
          required documents below</label>
        <app-file-dropper *ngIf="parentForm.value" (fileChangeEvent)="uploadFiles($event)" [value]="parentForm.value.files"></app-file-dropper>
      </div>
    </section>
  </form>
</ng-container>