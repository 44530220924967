<cdk-virtual-scroll-viewport itemSize="81" class="w-full h-full">
  <table *ngIf="!isLoading" class="w-full p-20 md:p-30 -my-20 md:-my-30 table-auto custom-table" @fadeIn>
    <thead>
      <tr>
        <ng-container *ngFor="let cell of table.Header; let first=first; let last=last">
          <td [style.top]="offsetTranslate" class="sticky z-10 p-10 text-2xs font-semibold uppercase bg-white hover:bg-blue-lightest text-gray dark:bg-gray-darker dark:text-white whitespace-nowrap {{cell.WidthClass}}"
              (click)="table.orderBy(cell);">
            <span class="flex items-center cursor-pointer min-h-24 focus:outline-none"
                  [attr.aria-label]="'Sort by ' + cell.DisplayName">
              <span class="mr-5" [ngClass]="first ? 'ml-10' : ''">{{cell.DisplayName}}</span>
              <span class="material-icons text-gray dark:text-gray-lighter"
                    *ngIf="cell === table.OrderByColumn">{{table.OrderDirection === 'asc' ? 'arrow_drop_up' :
                'arrow_drop_down'}}</span>
            </span>
          </td>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *cdkVirtualFor="let row of table.Body; let index=index; let first=first">
        <tr class="cursor-pointer" aria-label="Go to Upload & Message Detail" matTooltip="Go to Upload & Message Detail"
            (click)="openFileMessageDetailsModal(row)">
          <td class="py-20 pl-20 pr-10 font-bold capitalize">
            <ng-container *ngFor="let advisor of row.advisors; let isLastAdvisor=last">{{advisor.firstName}}
              {{advisor.lastName}}
              <ng-container *ngIf="!isLastAdvisor">, </ng-container>
            </ng-container>
          </td>
          <td class="p-10 py-20 capitalize">
            <ng-container *ngFor="let c of row.clients; let lastClient=last">{{c.lastName}}{{c.firstName ? ', ' + c.firstName : ''}}<br>
            </ng-container>
          </td>
          <td class="p-10 py-20">
            <div class="flex flex-col gap-y-5">
              <span class="font-bold">{{row.investmentAccountModel || 'No Model'}}</span>
              <span>{{row.custodian}}{{row.accountNumber ? ' - ' + row.accountNumber : ''}}</span>
            </div>
          </td>
          <td class="p-10 py-20">
            <div class="flex flex-col gap-y-5">
              <span class="font-bold">{{row.files && row.files.length > 0 ? 'File Upload' : 'Message Upload'}}</span>
              <span>{{row.taskDescription}}</span>
            </div>
          </td>
          <td class="p-10 py-20 pr-20">
            <div class="flex flex-col gap-y-5">
              <span class="font-bold">{{(row.createdOn | date: 'MM/dd/yyyy')}}</span>
              <span>{{(row.createdOn | date: 'shortTime')}}</span>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!isLoading && !table.Body.length">
        <tr>
          <td colspan="100%" class="p-10 py-20">
            <span class="text-md">No uploads & messages found. Try expanding your search criteria.</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>
<div class="flex-grow w-full loader-wrap" *ngIf="isLoading" @fadeIn>
  <div class="loader-sm is-blue"></div>
</div>