<section class="flex w-full h-full p-20 md:p-30 bg-center bg-no-repeat bg-cover" style="background: url(./assets/images/wave-bg.svg)">
  <div class="flex flex-col w-full mx-auto">
    <!-- <div class="flex flex-row items-center mb-20 md:mb-30" @fadeIn>
      <h2 class="flex items-center mr-20 text-lg font-bold text-white"><span>Request Tracker</span></h2>
    </div> -->
    <div class="flex flex-col flex-grow overflow-hidden card">
      <div class="flex items-center justify-between p-20 border-b border-solid md:p-30 border-gray-lighter">
        <div class="flex flex-row">
          <h2 class="flex items-center mr-70 text-lg font-bold"><span>Request Tracker</span></h2>
          <div class="relative flex w-full input pl-30 md:w-300 focus:ring-2 focus:ring-blue-light">
            <mat-icon svgIcon="magnify" class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18">
            </mat-icon>
            <input class="w-full ml-5 font-medium text-sm outline-none appearance-none text-gray-dark" type="text" name="search"
              aria-label="Search Requests" placeholder="Search Requests..." (appDebouncedModelChanged)="filterRequests()"
              [(ngModel)]="searchTerm" />
          </div>
        </div>       
        <button mat-button class="flex items-center justify-center px-10 button is-primary is-inverted"
          aria-label="Toggle Filters" (click)="requestTrackerFilter.toggle()">
          <mat-icon svgIcon="filter" class="mr-10 mi-18"></mat-icon>
          <span class="mr-5">Filters</span>
        </button>
      </div>
      <mat-sidenav-container autosize="true" class="flex h-full overflow-hidden bg-white">
        <mat-sidenav-content class="flex flex-col flex-grow overflow-hidden h-full">
          <app-request-tracker-list class="pb-20 md:pb-30 w-full h-full overflow-auto flex-grow"
            [requests]="filteredRequests" [sortDirection]="sortDirection"  [filterStatus]="this.tableFilter.Status"></app-request-tracker-list>
        </mat-sidenav-content>
        <mat-sidenav #requestTrackerFilter [opened]="sidenavConfig.IsOpened" [mode]="sidenavConfig.Mode"
          class="flex flex-col w-250 gap-y-10" position="end">
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <label class="text-sm text-gray">Advisors</label>
            <ng-container *ngIf="!isArcAdvisor">
              <mat-checkbox class="flex py-2 text-sm" *ngFor="let advisor of tableFilter.Advisors"
                [attr.aria-label]="advisor.Name + ' checkbox'" [(ngModel)]="advisor.Checked"
                (ngModelChange)="filterRequests()" [disabled]="isLoading">
                {{advisor.Name}}
              </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="isArcAdvisor">
              <div class="relative flex w-full input pl-30 md:w-250 focus:ring-2 focus:ring-blue-light">
                <mat-icon svgIcon="magnify"
                  class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18">
                </mat-icon>
                <input class="w-full ml-5 font-medium outline-none appearance-none text-gray-dark text-sm" type="text"
                  name="search" aria-label="Search advisor" placeholder="Filter..." [(ngModel)]="advisorSearchTerm"
                  (appDebouncedModelChanged)="filterAdvisorList()" />
                <mat-icon (click)="advisorSearchTerm=''; filterAdvisorList()" *ngIf="advisorSearchTerm.length > 0"
                  svgIcon="close"
                  class="absolute right-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-15 cursor-pointer">
                </mat-icon>
              </div>
              <div class="h-240 overflow-y-scroll">
                <mat-radio-group class="flex flex-col flex-none gap-y-10" aria-label="Advisors list"
                  [(ngModel)]="selectedRadioAdvisorId" [disabled]="isLoading">
                  <mat-radio-button class="flex py-2 text-sm"
                    *ngFor="let advisorRadio of filteredAdvisors; let index = index" [value]="advisorRadio.Value"
                    [attr.aria-label]="advisorRadio.Name + ' radio button'" [disabled]="isLoading"
                    (click)="radioAdvisorChecked(advisorRadio.Value)">
                    {{advisorRadio.Name}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </ng-container>
          </section>
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <label class="text-sm text-gray">Request Type</label>
            <mat-checkbox class="flex py-2 text-sm" *ngFor="let type of tableFilter.Types"
              [attr.aria-label]="type.Name + ' type checkbox'" [(ngModel)]="type.Checked"
              (ngModelChange)="filterRequests()" [disabled]="isLoading">
              {{type.Name}}
            </mat-checkbox>
          </section>
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <label class="text-sm text-gray mt-10">Status</label>
            <mat-button-toggle-group class="button-toggle-group mb-20" [(ngModel)]="tableFilter.Status"
              name="requestStatus" aria-label="Request Status" [disabled]="isLoading">
              <mat-button-toggle class="w-1/3 min-w-72" value="All" (change)="filterRequests()">All</mat-button-toggle>
              <mat-button-toggle class="w-1/3 min-w-72" value="Open"
                (change)="filterRequests()">Open</mat-button-toggle>
              <mat-button-toggle class="w-1/3 min-w-72" value="Closed"
                (change)="filterRequests()">Closed</mat-button-toggle>
            </mat-button-toggle-group>
          </section>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </div>
</section>