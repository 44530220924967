<section class="flex w-full h-full p-20 md:p-30 bg-top bg-no-repeat bg-cover" style="background: url(./assets/images/wave-bg.svg)">
    <section class="relative z-10 grid w-full grid-cols-1 mx-auto gap-20 lg:gap-30" [@fadeIn]="!isLoading">
        <div class="flex">
            <h2 class="flex items-center mr-70 text-lg font-bold text-white"><span>Resource Center</span></h2>            
            <div class="relative flex w-full pr-10 m-10 input pl-30 md:w-300 focus:ring-2 focus:ring-blue-light">
                <mat-icon svgIcon="magnify" class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18"></mat-icon>
                <input
                       type="text"
                       class="w-full ml-5 text-sm font-medium outline-none appearance-none text-gray-dark"
                       placeholder="Search Resources..."
                       [(ngModel)]="searchTerm"
                       (ngModelChange)="searchTermChanged($event)" />
              </div>
        </div>
        <ng-container *ngIf="(searchTerm.length || searchResults.length) && wistiaProject">
            <section class="flex flex-col flex-none w-full overflow-hidden card">
                <div class="flex items-center p-10 border-b border-solid border-gray-lighter">
                    <h2 class="flex-none m-10 text-lg font-bold">Search Results</h2>
                </div>
                <div class="p-20 min-h-60">
                    <h2 *ngIf="!isSearching && !searchResults.length" class="font-medium text-gray">No results found.</h2>
                    <ul *ngIf="isSearching || searchResults.length" class="flex flex-row flex-wrap h-full">
                        <li *ngFor="let asset of searchResults" class="flex items-center w-1/2 p-10 md:w-1/4 text-sm">
                            <span class="transition duration-150"
                                [class]="asset.mediaType === 'Video' ? 'wistia_embed wistia_async_' + asset.assetId + ' popover=true popoverContent=link inline relative' : ''">
                                <span class="flex items-center">
                                    <!-- <mat-icon class="mi-18 text-gray">{{asset.mediaType === 'File' ? 'description' : asset.mediaType === 'Video' ? 'slideshow' : 'language'}}</mat-icon> -->
                                    <img *ngIf="asset.mediaType === 'File'" class="w-auto h-24 m-auto" src="assets/images/ico_document.png"/>
                                    <img *ngIf="asset.mediaType === 'Video'" class="w-auto h-24 m-auto" src="assets/images/ico_video.png"/>  
                                    <a class="ml-10 font-semibold underline text-gray-dark text-m hover:text-blue-dark focus:text-blue-dark"
                                        [href]="asset.mediaType === 'Video' ? '#' : 'api/wistia/assets/' + asset.assetId" [attr.target]="asset.mediaType === 'Video' ? null : '_blank'"  [attr.aria-label]="'Open ' + asset.name" rel="noopener">{{asset.name}}</a>
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </section>
            <span class="divider-horizontal"></span>
        </ng-container>
        <section class="grid grid-cols-1 pb-5 md:grid-cols-2 gap-20 lg:gap-30">
            <ul class="grid h-300" *ngFor="let resource of resourcesFeatured">
                <li class="flex">
                    <div class="flex flex-grow overflow-hidden card">
                        <div class="flex flex-col flex-grow p-10">
                            <h2 class="mb-10 ml-10 mt-5 text-lg font-bold">{{resource.Name}}</h2>
                            <ul class="flex flex-col">
                                <li *ngFor="let asset of resource.Assets" class="flex items-center ml-10 mb-5 text-sm">
                                    <span class="transition duration-150"
                                        [class]="asset.mediaType === 'Video' ? 'wistia_embed wistia_async_' + asset.assetId + ' popover=true popoverContent=link inline relative' : ''">
                                        <span class="flex items-center">
                                            <!-- <mat-icon class="mi-18 text-blue">{{asset.mediaType === 'File' ? 'description' : asset.mediaType === 'Video' ? 'slideshow' : 'language'}}</mat-icon> -->
                                            <img *ngIf="asset.mediaType === 'File'" class="w-auto h-24 m-auto" src="assets/images/ico_document.png"/>
                                            <img *ngIf="asset.mediaType === 'Video'" class="w-auto h-24 m-auto" src="assets/images/ico_video.png"/>  
                                            <a class="ml-10 font-semibold underline text-gray-dark text-m hover:text-blue-dark focus:text-blue-dark"
                                                [href]="asset.mediaType === 'Video' ? '#' : 'api/wistia/assets/' + asset.assetId" [attr.target]="asset.mediaType === 'Video' ? null : '_blank'"  [attr.aria-label]="'Open ' + asset.name" rel="noopener">{{asset.name}}</a>
                                        </span>
                                    </span>                                
                                </li>
                                <li class="flex items-center m-10" *ngIf="resource.ViewMore?.Name">
                                    <i class="mr-10 material-icons mi-18 text-gray">more_horiz</i>
                                    <a (click)="navigate(resource)" class="font-semibold underline cursor-pointer text-m text-blue-dark hover:text-blue-darker text-sm">
                                        View more
                                    </a>
                                </li>
                            </ul>
                            <a *ngIf="resource.Sso" mat-button class="mt-20 m-10 leading-tight justify-center button is-primary flex items-center" [href]="resource.Sso.Url" target="_blank" rel="noopener">
                                <div class="flex items-center justify-center">
                                  <span>{{resource.Sso.Name}}</span>
                                  <mat-icon svgIcon="open-in-new" class="pl-10 mt-2"></mat-icon>
                                </div>
                            </a>
                        </div>
                        <div class="flex flex-row flex-none w-2/5 bg-center bg-no-repeat bg-cover" [style.background]="'url(' + resource.BackgroundUrl + ')'">
                            <div class="flex-grow-0 w-48 h-full mr-auto bg-left bg-no-repeat bg-cover" style="background:url('assets/images/sm-wave-vertical.svg')"></div>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
        <section class="grid grid-cols-1 gap-20 lg:gap-30">           
            <mat-accordion [multi]="false">
                <ng-container *ngFor="let resource of resourcesViewMore; let last = last">
                    <div *ngIf="resource.Name">
                        <mat-expansion-panel class="rounded-lg shadow text-gray-dark" [class]="!last ? 'mb-20 lg:mb-30' : ''" hideToggle [id]="resource.HtmlId"
                        [(expanded)]="resource.Expanded" #panel="matExpansionPanel">
                        <mat-expansion-panel-header class="flex h-64 p-20" matTooltip="Expand/Collapse Item">
                            <h2 class="m-10 text-lg font-bold">{{resource.Name}}</h2>
                            <i class="m-10 ml-auto transition origin-center material-icons text-gray" [ngClass]="{'transform rotate-180': panel.expanded}">expand_more</i>
                        </mat-expansion-panel-header>
                        <div class="border-t border-solid md:p-10 border-gray-lighter">
                            <ul class="flex flex-row flex-wrap p-10" *ngIf="resource.Assets">
                                <li *ngFor="let asset of resource.Assets" class="flex items-center w-1/2 p-10 md:w-1/4 text-sm">
                                    <span class="transition duration-150"
                                        [class]="asset.mediaType === 'Video' ? 'wistia_embed wistia_async_' + asset.assetId + ' popover=true popoverContent=link inline relative' : ''">
                                        <span class="flex items-center">
                                            <!-- <mat-icon class="mi-18 text-blue">{{asset.mediaType === 'File' ? 'description' : asset.mediaType === 'Video' ? 'slideshow' : 'language'}}</mat-icon> -->
                                            <img *ngIf="asset.mediaType === 'File'" class="w-auto h-24 m-auto" src="assets/images/ico_document.png"/>
                                            <img *ngIf="asset.mediaType === 'Video'" class="w-auto h-24 m-auto" src="assets/images/ico_video.png"/>  
                                            <a class="ml-10 font-semibold underline text-gray-dark text-m hover:text-blue-dark focus:text-blue-dark"
                                                [href]="asset.mediaType === 'Video' ? '#' : 'api/wistia/assets/' + asset.assetId" [attr.target]="asset.mediaType === 'Video' ? null : '_blank'"  [attr.aria-label]="'Open ' + asset.name" rel="noopener">{{asset.name}}</a>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div *ngFor="let subsection of resource.SubSections" class="flex flex-col p-10">
                                <h3 class="m-10 uppercase font-semibold text-gray whitespace-nowrap">{{subsection.Name}}</h3>
                                <ul class="flex flex-row flex-wrap">
                                    <li *ngFor="let asset of subsection.Assets" class="flex items-center w-1/2 p-10 md:w-1/4 text-sm">
                                        <span class="transition duration-150"
                                            [class]="asset.mediaType === 'Video' ? 'wistia_embed wistia_async_' + asset.assetId + ' popover=true popoverContent=link inline relative' : ''">
                                            <span class="flex items-center">
                                                <!-- <mat-icon class="mi-18 text-blue">{{asset.mediaType === 'File' ? 'description' : asset.mediaType === 'Video' ? 'slideshow' : 'language'}}</mat-icon> -->
                                                <img *ngIf="asset.mediaType === 'File'" class="w-auto h-24 m-auto" src="assets/images/ico_document.png"/>
                                                <img *ngIf="asset.mediaType === 'Video'" class="w-auto h-24 m-auto" src="assets/images/ico_video.png"/>
                                                <a class="ml-10 font-semibold underline text-gray-dark text-m hover:text-blue-dark focus:text-blue-dark"
                                                    [href]="asset.mediaType === 'Video' ? '#' : 'api/wistia/assets/' + asset.assetId" [attr.target]="asset.mediaType === 'Video' ? null : '_blank'"  [attr.aria-label]="'Open ' + asset.name" rel="noopener">{{asset.name}}</a>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    </div>                 
                </ng-container>
            </mat-accordion>
        </section>
    </section>
</section>