import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Players } from 'src/app/portal/features/example/constants/players';
import { Player } from 'src/app/portal/features/example/models/player';

@Injectable({
  providedIn: 'root'
})
export class PlayersApiService {

  getPlayersByTeamId(TeamId:number):Observable<Player[]> {
    // of function converts this call into an observable, which mimics the way angular handles http calls
    return of(Players)
      .pipe(
        map(players => players.filter(player => player.TeamId == TeamId))
      );
  }

  getPlayerById(Id:number):Observable<Player> {
    /* pipe is a way to manipulate data of a stream, you can nest multiple functions(referred to as operators in rxJS) withing the pipe operator.
    NOTE: The mapping operator doesn't happen until this method is Subsribed to */
    return of(Players)
      .pipe(
        map(players => players.filter(player => player.Id == Id)[0])
      );
  }
}
