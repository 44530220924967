<form [formGroup]="firstMfaSetupForm" class="flex flex-col flex-auto" (submit)="onSubmit()">
    <div>
        <h4 class="leading-tight text-2xl font-normal text-center m-30">Multi-Factor Authentication Setup</h4>
    </div>
    <div class="my-10 mx-32 p-10 md:p-20 text-gray-dark text-lg  bg-blue-lightest rounded">
        <p>For you protection, we now require the use of multi-factor authentication to access the Alphastar
            Portal. To set up multi-factor authentication, you will need access to a mobile device that accepts text
            messages.<br />
            Please enter the device phone number you wish to use below and click Submit.
        </p>
    </div>
    <div class="my-10 mx-32 flex flex-shrink-0 flex-col">
        <label class="label mb-5">Phone Number</label>
        <input type="tel" name="phoneNumber" formControlName="phoneNumber" autofocus class="input" pattern="[0-9]{10}">
    </div>
    <div class="mx-32 my-30 flex flex-col flex-none">
        <button class="button is-primary is-outlined focus:ring-0" mat-button type="submit">Submit</button>
    </div>
</form>