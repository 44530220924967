<form [formGroup]="mfaVerificationForm" class="flex flex-col flex-auto" (submit)="onSubmit()">

    <div>
        <h4 class="leading-tight text-2xl font-normal text-center m-30">{{title}}</h4>
    </div>

    <div class="my-10 mx-30 p-10 md:p-20 text-gray-dark text-lg  bg-blue-lightest rounded">
        <p>{{description}}</p>
    </div>
    <div class="my-10 mx-30 flex flex-shrink-0 flex-col">
        <label class="label mb-5">PIN</label>
        <input type="text" name="mfaCode" autocomplete="off" formControlName="mfaCode" maxlength="6" autofocus class="input" placeholder="6-digit code">
        <span *ngIf="formSubmitting && mfaVerificationForm.invalid" class="text-sm mt-5 text-red">
            <span *ngIf="mfaVerificationForm.controls.mfaCode.hasError('required')">This field is required</span>
            <span *ngIf="mfaVerificationForm.controls.mfaCode.hasError('mfaInvalid')">Please enter a valid 6-digit PIN</span>
        </span>
    </div>
    <div *ngIf="context === 'login'" class="my-10 mx-30 flex flex-shrink-0 flex-col">
        <mat-checkbox name="rememberMe" formControlName="rememberMe" class="text-sm text-gray">
            Remember this computer. You won't need to re-enter your PIN for 30 days.
        </mat-checkbox>
    </div>
    <div class="mx-32 my-30 flex flex-col flex-none gap-y-10">
        <button class="button is-primary is-outlined is-lg focus:ring-0" mat-button type="submit">Submit</button>
        <span *ngIf="hasError" class="p-10 border-2 border-red flex text-sm justify-center text-red bg-red-lightest">An error occured. Please verify you entered the correct 6-digit pin. If this error continues, please contact your advisor services manager.</span>
    </div>
</form>