<section class="flex flex-col w-full h-full min-w-460 min-h-256">
  <header *ngIf="data.title">
    <h2 class="m-10 text-lg font-bold">{{data.title}}</h2>
  </header>
  <section class="dialog-content">
    <p class="m-auto text-lg">{{data.message}}</p>
  </section>
  <footer>
    <button mat-button class="m-10 button is-primary is-outlined flex justify-center items-center min-w-128" [mat-dialog-close]="false" *ngIf="!data.hideCancelButton">Cancel</button>
    <button mat-button class="m-10 ml-auto button flex justify-center items-center min-w-128" [class]="data.isWarning ? 'is-warning' : 'is-primary'"
            [mat-dialog-close]="true">{{data.confirmationButtonText ? data.confirmationButtonText : 'Confirm'}}</button>
  </footer>
</section>