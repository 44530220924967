import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginClient, PasswordResetClient } from 'src/app/core/clients/generated/client';
import { ValidateMfaPin } from 'src/app/shared/validators/login.validator';

@Component({
  selector: 'app-mfa-verification-form',
  templateUrl: './mfa-verification-form.component.html',
})
export class MfaVerificationFormComponent {
  @Output() mfaSucceded = new EventEmitter<boolean>();
  @Input() context: MfaVerificationContext = 'login';
  @Input() title = 'We sent you a text message.';
  @Input() description = 'Please enter the 6-digit PIN sent to the primary SMS device registered to this account.';
  formSubmitting = false;
  hasError = false;
  mfaVerificationForm = this.fb.group({
    mfaCode: this.fb.control<string>('', [Validators.required, ValidateMfaPin]),
    rememberMe: this.fb.control(false)
  });

  constructor(
    private loginClient: LoginClient,
    private passwordResetClient: PasswordResetClient,
    private fb: FormBuilder,
  ) { }

  onSubmit(): void {
    this.formSubmitting = true;
    this.mfaVerificationForm.markAllAsTouched();
    if (this.mfaVerificationForm.invalid) {
      this.hasError = true;
      return;
    }

    if (this.context == 'login') this.onLoginSubmit();
    else this.onPasswordResetSubmit();
  }

  onLoginSubmit(): void {
    this.loginClient.verifyMfa({
      mfaCode: this.mfaVerificationForm.value.mfaCode || '',
      rememberMe: this.mfaVerificationForm.value.rememberMe || false,
    })
      .subscribe({
        next: () => this.mfaSucceded.emit(true),
        error: () => this.mfaSucceded.emit(false),
      });
  }

  onPasswordResetSubmit(): void {
    this.hasError = false;
    this.passwordResetClient.verifyMfa({
      mfaCode: this.mfaVerificationForm.value.mfaCode ?? ''
    })
      .subscribe({
        next: () => this.mfaSucceded.emit(true),
        error: () => {
          this.hasError = true;
        }
      });
  }
}


export type MfaVerificationContext = 'login' | 'password-reset';
