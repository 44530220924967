import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CurrencyMaskConfig, CurrencyMaskInputMode} from 'ngx-currency';
import { CustomCurrencyMaskConfig } from 'src/app/shared/config/currency-mask.config';

@Component({
  selector: 'app-tax-options',
  templateUrl: './tax-options.component.html'
})
export class TaxOptionsComponent {
  @Input() taxFormGroup?: UntypedFormGroup;

  stateTaxMaskOptions: CurrencyMaskConfig = {
    ...CustomCurrencyMaskConfig, ...{
      allowNegative: false,
      prefix: '',
      suffix: '%',
      max: 100.00,
      inputMode: CurrencyMaskInputMode.NATURAL,
    }
  };
  federalTaxMaskOptions: CurrencyMaskConfig = {
    ...CustomCurrencyMaskConfig, ...{
      allowNegative: false,
      prefix: '',
      suffix: '%',
      max: 100.00,
      inputMode: CurrencyMaskInputMode.NATURAL,
    }
  };
}
