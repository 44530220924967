<div id="investmentStrategyModal" class="flex flex-col w-full h-full">
  <header class="flex items-center flex-none p-10 border-b border-solid md:p-20 border-gray-lighter">
    <div class="flex items-center flex-none">
      <span *ngFor="let logo of data.LogoUrls"
        class="flex items-center justify-center p-10 rounded-full w-72 h-72 bg-gray-lightest mr-5">
        <img class="w-full h-auto" [src]="logo">
      </span>
    </div>
    <div class="flex flex-col flex-wrap p-10">
      <h2 class="flex  m-10 text-lg font-bold">{{data.Name}}</h2>
    </div>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" tabindex="-1" type="button"
      mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="flex flex-col flex-grow h-full">
    <section class="flex flex-col p-10 overflow-auto md:p-20 dialog-content">
      <div class="flex flex-col">
        <h3 class="font-bold text-gray-dark pb-10">Model Information</h3>
        <div class="flex flex-col">              
          <div class="flex-col pb-10" *ngIf="data.Description">
            <p class="text-sm">{{data.Description}}</p>
          </div>      
          <div class="flex flex-col bg-gray-lightest pl-20 pr-40 pt-15 pb-15 rounded mt-2"
            *ngIf="data.RiskTolerance || data.MoneyManager || data.ManagerFee || data.ModelMinimum">
            <div class="flex flex-row justify-between w-full gap-x-10">
              <span class="text-sm font-bold">Risk Tolerance</span>
              <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
              <span class="text-sm font-semibold text-gray-dark">{{data.RiskTolerance}}</span>
            </div>
            <div class="flex flex-row justify-between w-full gap-x-10 mt-8">
              <span class="text-sm font-bold">Money Manager</span>
              <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
              <span class="text-sm font-semibold text-gray-dark">{{data.MoneyManager}}</span>
            </div>
            <div class="flex flex-row justify-between w-full gap-x-10  mt-8">
              <span class="text-sm font-bold">Manager Fee</span>
              <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
              <span class="text-sm font-semibold text-gray-dark">{{data.ManagerFee}} bps</span>
            </div>
            <div class="flex flex-row justify-between w-full gap-x-10 mt-8">
              <span class="text-sm font-bold">Model Minimum</span>
              <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
              <span class="text-sm font-semibold text-gray-dark">{{data.ModelMinimum | currency}}</span>
            </div>                      
          </div>          
        </div>
        <h3 class="font-bold text-gray-dark mt-25"> Resources</h3> 
        <div class="grid grid-cols-1 gap-10 pt-10 sm:grid-cols-2">                      
          <ng-container *ngFor="let info of data.InvestmentLinks">
            <span
              class="flex items-center w-full transition duration-150 rounded cursor-pointer text-blue-dark hover:bg-blue-lightest">
              <a class="flex items-center w-full no-underline rounded cursor-pointer text-blue-dark hover:no-underline focus:no-underline"
                [href]="info.Link" target="_blank" rel="noopener">
                <div class="flex items-center justify-center flex-shrink-0 w-48 h-48">
                  <mat-icon class="mi-18 text-blue-dark">language</mat-icon>
                </div>
                <span class="text-sm font-bold leading-normal">{{info.Title}}</span>
              </a>
            </span>
          </ng-container>
          <ng-container *ngFor="let asset of data.Assets">
            <span
              class="flex items-center w-full transition duration-150 rounded cursor-pointer text-blue-dark hover:bg-blue-lightest"
              [class]="asset.mediaType === 'Video' ? 'wistia_embed wistia_async_' + asset.assetId + ' popover=true popoverContent=link inline relative' : ''">
              <a class="flex items-center w-full no-underline rounded cursor-pointer text-blue-dark hover:no-underline focus:no-underline"
                [href]="asset.mediaType === 'Video' ? '#' : 'api/wistia/assets/' + asset.assetId"
                [attr.target]="asset.mediaType === 'Video' ? null : '_blank'" [attr.aria-label]="'Open ' + asset.name"
                rel="noopener">
                <div class="flex items-center justify-center flex-shrink-0 w-48 h-48">
                  <mat-icon class="mi-18 text-blue-dark">{{asset.mediaType === 'File' ? 'description' : asset.mediaType
                    === 'Video' ? 'slideshow' : 'language' }}</mat-icon>
                </div>
                <span class="text-sm font-bold leading-normal">{{asset.name}}</span>
              </a>
            </span>
          </ng-container>
        </div>
        <h2 class="mt-10 text-gray" *ngIf="!data.Assets.length && !data.Error">No content available.</h2>
        <div class="flex items-center p-10 border border-solid rounded border-red bg-red-lightest gap-x-10"
          *ngIf="!data.Assets.length && data.Error">
          <mat-icon svgIcon="alert" class="flex-none"></mat-icon>
          <p>We were unable to retrieve content for this investment model. Please try again later.</p>
        </div>
      </div>
    </section>
  </div>
  <ng-container *appShowIfAll="perm.ViewBetashieldSso">
    <footer class="flex items-center justify-end flex-none p-10 border-t border-solid md:p-20 border-gray-lighter"
      *ngIf="data.IsBetaShield">
      <a mat-button class="flex items-center justify-center m-10 leading-tight button is-primary is-lg"
        href="api/sso/beta-shield" target="_blank" rel="noopener">
        <div class="flex items-center justify-center">
          <span>Launch Betashield</span>
          <mat-icon svgIcon="open-in-new" class="pl-10 mt-2"></mat-icon>
        </div>
      </a>
    </footer>
  </ng-container>
</div>