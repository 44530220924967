<cdk-virtual-scroll-viewport itemSize="81" class="w-full h-full">
  <table *ngIf="!isLoading" class="w-full p-20 md:p-30 md:-my-20 table-auto custom-table" @fadeIn>
    <thead>
      <tr>
        <ng-container *ngFor="let header of table.Header; let first=first; let last=last">
          <td [style.top]="offsetTranslate"
            class="sticky text-2xs font-semibold uppercase bg-white hover:bg-blue-lightest text-gray dark:bg-gray-darker dark:text-white whitespace-nowrap {{header.WidthClass}}"
            (click)="table.orderBy(header);">
            <span class="flex items-center cursor-pointer min-h-24 focus:outline-none"
              [attr.aria-label]="'Sort by ' + header.DisplayName">
              <span class="mr-5" [ngClass]="first ? 'ml-20' : ''">{{header.DisplayName}}</span>
              <span class="material-icons text-gray dark:text-gray-lighter"
                *ngIf="header === table.OrderByColumn">{{table.OrderDirection === 'asc' ? 'arrow_drop_up' :
                'arrow_drop_down'}}</span>
            </span>
          </td>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *cdkVirtualFor="let row of table.Body; let index=index; let first=first">
        <tr class="cursor-pointer" aria-label="Go to Request Detail" (click)="openRequestDetailModal(row)">
          <td class="font-bold capitalize p-20">
            <div class="flex flex-col gap-y-10">
              <ng-container *ngFor="let a of row.advisors; let lastAdvisor=last">{{a.firstName}}
                {{a.lastName}}<ng-container *ngIf="!lastAdvisor">, </ng-container><br>
              </ng-container>
            </div>
          </td>
          <td>
            <div class="flex flex-col gap-y-10">
              <ng-container *ngFor="let c of row.clients; let lastClient=last">{{ c.lastName ? (c.lastName + ', ' +
                c.firstName) : c.firstName }}<ng-container *ngIf="!lastClient">, </ng-container><br>
              </ng-container>
            </div>
          </td>
          <td>
            <!--account-->
            <ng-container>
              <span class="font-bold">{{row.custodian ? row.custodian : ''}}{{row.investmentAccountNumber ? ' - ' +
                row.investmentAccountNumber
                : ''}}</span><br />
              <span>{{row.investmentAccountModel ? row.investmentAccountModel : ''}}</span>
            </ng-container>
          </td>
          <td>
             <!--type-->
            <span>{{row.category}} {{row.subCategory !== null ? ' - ' + row.subCategory : ''}}</span>           
          </td>
          <td>
            <div class="flex flex-col gap-y-5">
              <ng-container>
                <span>{{row.statusDate | date: 'shortDate'}}</span>
                <span>{{row.statusDate | date: 'shortTime'}}</span>
              </ng-container>
            </div>
          </td>
          <td>
            <span [ngStyle]="{'color': 
            row.statusId === 4 ? '#D71D00' : 
            row.statusId === 5 ? '#127161' : 
            row.statusId === 6 ? '#FFAF3D' : 
            row.statusId === 7 ? '#D71D00' : 
            row.statusId === 8 ? '#D71D00' : 
            row.statusId === 9 ? '#D71D00' : 
            '#1C3F76' }">{{row.status}}</span>&nbsp;
            <mat-icon *ngIf="row.statusId === 4 || row.statusId === 7 || row.statusId === 8 || row.statusId === 9"
              class="mi-18 text-red" svgIcon="information"
              matTooltip="Click into the request to view action needed. To send required information, click the envelope to the right."
              matTooltipPosition="after"
              matTooltipClass="text-sm border rounded-t rounded-b border-blue-lighter bg-blue-lightest text-gray-dark">
            </mat-icon>
          </td>
          <td>            
            <ng-container *appShowIfAll="perm.SubmitMessageFile">
              <button *ngIf="row.statusId !== 5" class="text-sm ml-auto button icon-button is-primary is-inverted" 
              mat-button matTooltip="Send Message with Files"
                (click)="$event.stopPropagation(); openFileMessageUploadModal(row)">
                <span class="material-icons">mail</span>
              </button>
            </ng-container>
            <!--<button class="ml-auto button icon-button is-primary is-inverted" mat-button
              [matMenuTriggerFor]="accountMenu" *ngIf="row.investmentAccountId" (click)="$event.stopPropagation()">
              <span class="material-icons">more_horiz</span>
            </button>
            <mat-menu #accountMenu="matMenu" aria-label="Open Actions" matTooltip="Open Actions">
              <div class="flex flex-col items-start gap-y-10">>
                <ng-container *appShowIfAll="perm.SubmitMessageFile">
                  <button class="flex items-center justify-start button is-primary is-lg is-inverted text-gray-darker"
                    mat-menu-item (click)="openFileMessageUploadModal(row)" aria-label="Open uploads & messages modal">
                    <span class="my-auto ml-10">Upload Files & Messages</span>
                  </button>
                </ng-container>
              </div>
            </mat-menu>-->
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!isLoading && !table.Body.length">
        <tr>
          <td colspan="100%" class="p-10 py-20">
            <div class="text-base">No 
              <span class="font-bold text-lg" [ngStyle]="{'color': 
              this.filterStatus === 'Open' ? '#127161' : 
              this.filterStatus === 'Closed' ? '#127161' :
              '#1C3F76' }"> {{this.filterStatus === 'Open' ? 'OPEN' : this.filterStatus === 'Closed' ? 'CLOSED' : ''}}</span>
              requests found. Try expanding your search criteria.</div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>
<div class="flex-grow w-full loader-wrap" *ngIf="isLoading" @fadeIn>
  <div class="loader-sm is-blue"></div>
</div>