<ng-container *ngIf="parentForm">
  <form [formGroup]="parentForm" class="flex flex-col w-full">
    <div class="flex flex-col overflow-auto p-30 gap-y-30">
      <div class="flex flex-col gap-y-30">

        <div class="flex flex-col gap-y-10 w-full -mt-5">
          <div>
            <label class="text-base font-bold text-gray-dark required-label" for="net-amount">Would you like to enter the GROSS or NET amount?</label>
            <mat-icon class="mi-20 text-blue-light pl-5" svgIcon="information" matTooltip="
            Gross Amount - Amount that will be distributed from the account.&NewLine;&NewLine;Net Amount - Amount that the client will receive after applicable tax withholdings."
              matTooltipPosition="after"
              matTooltipClass="text-sm border rounded-t rounded-b border-blue-lighter bg-blue-lightest text-gray-dark">
            </mat-icon>
          </div>
          <mat-button-toggle-group id="gross-net-toggle" formControlName="type" class="w-200 button-toggle-group"
            autofocus>
            <mat-button-toggle class="w-full" [value]="Gross">GROSS
            </mat-button-toggle>
            <mat-button-toggle class="w-full" [value]="Net">NET
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>


        <div class="flex flex-col gap-y-10">
          <label class="text-base font-bold text-gray-dark required-label" for="net-amount">Enter <span
              class='text-blue-dark uppercase'>{{parentForm.controls.type.value}}</span> amount.</label>
       
          <div class="relative flex">
            <input class="text-lg text-gray-dark w-full border input is-inverted is-lg border-gray-light" type="text"
              id="net-amount" aria-label="Net amount" currencyMask [options]="netAmountCurrencyMaskOptions"
              placeholder="$0.00" formControlName="typeAmount" />
            <div
              class="absolute my-0 mr-0 h-full right-0 border-t border-b border-r border-gray rounded-tr rounded-br flex items-center justify-center bg-gray text-white p-2 w-1/5">
              <label class="text-sm font-semibold">{{parentForm.controls.type.value}} Amount</label>
            </div>
            <span class="absolute left-0 top-40 mt-5 flex flex-col gap-y-5 text-xs text-red font-medium"
              *ngIf="parentForm.controls.typeAmount.touched">
              <span *ngIf="parentForm.controls.typeAmount.hasError('required')">This field is required.</span>
            </span>
          </div>         
        </div>

        <!-- Tax withholding -->
        <div class="flex flex-col gap-y-10">
          <label class="text-base font-bold text-gray-dark required-label" for="gross-amount">Enter the Account's Federal and
            State Tax Withholdings.</label>       
          <app-tax-options [taxFormGroup]="parentForm"></app-tax-options>
        </div>
        <!-- Negative balance warning -->
        <app-message-notification class="mt-50" @fadeIn panelType="alert" *ngIf="hasAlert">The distribution amount is greater than the
          account value of {{ this.account?.accountValue | currency: '$'}}.</app-message-notification>

        <!-- Gross/Net balance information -->
        <span @fadeIn *ngIf="!hasAlert && displayNetGrossInformationBox"
          class="p-10 border border-solid rounded border-blue-lighter bg-blue-lightest text-gray-dark">
          <span class="text-sm">
            <div class="flex flex-col my-auto items-center text-base gap-y-20">
              <div *ngIf="displayGrossAmount !== undefined"><span class="font-bold text-blue-dark ">{{displayGrossAmount
                  | currency}}</span> will be distributed from this account.</div>
              <div *ngIf="displayNetAmount !== undefined">Your client will receive <span
                  class="font-bold text-blue-dark ">{{displayNetAmount | currency}}</span> after applicable tax
                withholdings.</div>
            </div>
          </span>
        </span>
      </div>
    </div>
  </form>

</ng-container>