<ng-container *ngIf="requestType">
    <section class="flex flex-col flex-grow overflow-auto p-30 gap-y-20 gap-x-20">
        <p class="mb-10 text-lg">Please review your request below and make sure all the information is accurate.</p>
        <!--Contribution-->
        <ng-container *ngIf="requestType === requestTypeEnum.contribution">
            <div class="flex flex-row justify-between w-full gap-x-10">
                <span class="font-bold">Gross Contribution Amount</span>
                <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                <span class="font-bold text-blue-dark">{{(reviewModel?.amount || 0) | currency}}</span>
            </div>
            <span class="flex-none divider-horizontal"></span>
            <div class="flex flex-col gap-y-10">
                <div *ngFor="let item of reviewModel?.sleeves" class="flex flex-row justify-between w-full gap-x-10">
                    <span>{{item.name || 'No Current Model'}}</span>
                    <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                    <span class="font-bold text-gray-dark">{{(item?.amount || 0) | currency}} ({{(item.percent |
                        percent:'1.0-2')}})</span>
                </div>
                <div class="flex flex-row justify-between w-full gap-x-10" *ngIf="reviewModel?.protectedCash">
                    <span>Cash</span>
                    <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                    <span class="font-bold text-gray-dark">{{(reviewModel?.protectedCash?.amount || 0) | currency}}
                        (Client Fee: {{reviewModel?.protectedCash?.fee}}%)</span>
                </div>
                <div *ngIf="reviewModel?.customHoldings" class="flex flex-col gap-y-10">
                    <div class="flex flex-row justify-between w-full gap-x-10">
                        <span>Custom Holdings</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-gray-dark">{{(reviewModel?.customHoldings?.amount || 0) | currency}}
                            ({{(reviewModel?.customHoldings?.percent | percent:'1.0-2')}})</span>
                    </div>
                    <div *ngFor="let item of reviewModel?.customHoldings?.holdings"
                        class="flex flex-row justify-between w-full pl-20 text-sm gap-x-10">
                        <span>{{item.name}}</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-green">{{item.type}} {{item.amount ? ((item.amount || 0) | currency)
                            : (item.shareCount || '') + ' Shares'}}</span>
                    </div>
                    <div class="flex flex-col gap-y-2 text-sm pl-20" *ngIf="reviewModel?.customHoldings?.notes?.length">
                        <span>Trade Instructions:</span>
                        <div class="flex flex-col p-5 rounded md:p-5 bg-gray-lightest">
                            <span
                                class="whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.customHoldings?.notes}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!--Distribution-->
        <ng-container *ngIf="requestType === requestTypeEnum.distribution">
            <div class="flex flex-col gap-y-10">
                <div class="flex flex-row justify-between w-full gap-x-10">
                    <span>Gross Distribution Amount</span>
                    <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                    <span class="font-bold text-gray-dark">{{(reviewModel?.amount || 0) | currency}}</span>
                </div>
                <ng-container *ngIf="reviewModel?.taxWithholding">
                    <div class="flex flex-row justify-between w-full gap-x-10">
                        <span>Federal Tax Withheld</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-gray-dark">{{(reviewModel?.taxWithholding?.federalPercent ||
                            0)}}%</span>
                    </div>
                    <div class="flex flex-row justify-between w-full gap-x-10">
                        <span>State Tax Withheld</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-gray-dark">{{(reviewModel?.taxWithholding?.statePercent ||
                            0)}}%</span>
                    </div>
                    <div class="flex flex-row justify-between w-full gap-x-10">
                        <span class="font-bold">Amount Client Will Receive</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-blue-dark">{{(reviewModel?.taxWithholding?.receiveAmount || 0) |
                            currency}}</span>
                    </div>
                </ng-container>
            </div>
            <span class="flex-none divider-horizontal"></span>
            <div class="flex flex-col gap-y-10">
                <div *ngFor="let item of reviewModel?.sleeves" class="flex flex-row justify-between w-full gap-x-10">
                    <span>{{item.name || 'No Current Model'}}</span>
                    <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                    <span class="font-bold text-gray-dark">{{(item?.amount || 0) | currency}}</span>
                </div>
                <div *ngIf="reviewModel?.customHoldings" class="flex flex-col gap-y-10">
                    <div class="flex flex-row justify-between w-full gap-x-10">
                        <span>Custom Holdings</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-gray-dark">{{(reviewModel?.customHoldings?.amount || 0) |
                            currency}}</span>
                    </div>
                    <div *ngFor="let item of reviewModel?.customHoldings?.holdings"
                        class="flex flex-row justify-between w-full pl-20 text-sm gap-x-10">
                        <span>{{item.name}}</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-orange">{{item.type}} {{item.amount ? ((item.amount || 0) |
                            currency) : (item.shareCount || '') + ' Shares'}}</span>
                    </div>
                    <div class="flex flex-col gap-y-4 text-sm pl-20" *ngIf="reviewModel?.customHoldings?.notes?.length">
                        <span>Trade Instructions:</span>
                        <div class="flex flex-col p-10 rounded md:p-10 bg-gray-lightest">
                            <span
                                class="mb-10 whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.customHoldings?.notes}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!--Reallocation-->
        <ng-container *ngIf="requestType === requestTypeEnum.reallocation">           
            <div class="flex flex-row justify-between w-full gap-x-10" *ngIf="!reviewModel?.isTradesOnly">
                <span class="font-bold">Total Funds</span>
                <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                <span class="font-bold text-blue-dark">{{(reviewModel?.amount || 0) | currency}}</span>
            </div>
            <span class="flex-none divider-horizontal" *ngIf="!reviewModel?.isTradesOnly"></span>
            <div class="flex flex-col gap-y-10">
                <div *ngFor="let item of reviewModel?.sleeves" class="flex flex-row justify-between w-full gap-x-10">
                    <span>{{item.name || 'No Current Model'}}</span>
                    <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                    <span class="font-bold text-gray-dark">{{(item?.accountTotalFee || 0).toFixed(3) }}%</span>
                    <span class="font-bold text-gray-dark">{{(item?.amount || 0) | currency}} ({{(item.percent |
                        percent:'1.0-2')}})</span>
                </div>
                <div *ngIf="reviewModel?.customHoldings" class="flex flex-col gap-y-10">
                    <div class="flex flex-row justify-between w-full gap-x-10">
                        <span>Custom Holdings</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold text-gray-dark" *ngIf="!reviewModel?.isTradesOnly">{{(reviewModel?.customHoldings?.accountTotalFee ||
                            0).toFixed(3) }}%</span>
                        <span class="font-bold text-gray-dark" *ngIf="!reviewModel?.isTradesOnly">{{(reviewModel?.customHoldings?.amount || 0) | currency}}
                            ({{(reviewModel?.customHoldings?.percent | percent:'1.0-2')}})</span>
                    </div>
                    <div *ngFor="let item of reviewModel?.customHoldings?.holdings"
                        class="flex flex-row justify-between w-full pl-20 text-sm gap-x-10">
                        <span>{{item.name}}</span>
                        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                        <span class="font-bold"
                            [ngClass]="item.type === 'Buy' ? 'text-green' : item.type === 'Sell' ? 'text-orange' : ''">{{item.type}}
                            {{item.amount ? ((item.amount || 0) | currency) : (item.shareCount || '') + '
                            Shares'}}</span>
                    </div>
                    <div class="flex flex-col gap-y-2 text-sm pl-20" *ngIf="reviewModel?.customHoldings?.notes?.length">
                        <span>Trade Instructions:</span>
                        <div class="flex flex-col p-5 rounded md:p-5 bg-gray-lightest">
                            <span
                                class="whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.customHoldings?.notes}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span class="flex-none mb-5 divider-horizontal"></span>
        </ng-container>
        <div *ngIf="reviewModel?.delivery"
            class="flex flex-row justify-between mt-10 mb-5 border-t border-b full-w py-30 border-gray-lighter">
            <div class="flex flex-col">
                <span>Method:</span><span class="font-bold text-gray-dark">{{reviewModel?.delivery?.method}}
                    {{reviewModel?.delivery?.methodEnum === deliveryMethodEnum.journalCash ? '(' +
                    reviewModel?.delivery?.journalAccountNumber +')' : reviewModel?.delivery?.methodEnum ===
                    deliveryMethodEnum.wireTransfer ? '(' + reviewModel?.delivery?.wireTransferTo +')' : ''}}
                </span>
            </div>
            <div class="flex flex-col"><span>Frequency:</span><span
                    class="font-bold text-gray-dark">{{reviewModel?.delivery?.frequency}}</span></div>
            <div class="flex flex-col pl-5"><span>Begins on:</span><span
                    class="font-bold text-gray-dark">{{(reviewModel?.delivery?.startDate | date: 'MM/dd/yyyy') ||
                    'N/A'}}</span></div>
        </div>
        <div class="flex flex-col gap-y-10">
            <span>Notes:</span>
            <div *ngIf="reviewModel?.notes?.length" class="flex flex-col p-10 rounded md:p-20 bg-gray-lightest">
                <span class="mb-10 whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.notes}}</span>
            </div>
            <em *ngIf="!reviewModel?.notes?.length" class="text-sm text-gray-light">No notes</em>
        </div>
        <div class="flex flex-col">
            <span>Files:</span>
            <div class="flex flex-col gap-y-5" class="font-bold" *ngFor="let item of reviewModel?.files">
                {{item.name}}
            </div>
        </div>
    </section>
</ng-container>