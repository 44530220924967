<section class="flex w-full h-full p-20 md:p-30 bg-center bg-no-repeat bg-cover" style="background: url(./assets/images/wave-bg.svg)">
  <div class="flex flex-col w-full mx-auto">
    <!-- <div class="flex flex-row items-center mb-20 md:mb-30" @fadeIn>
      <h2 class="flex items-center mr-20 text-lg font-bold text-white"><span>Uploads &amp; Messages</span></h2>
    </div> -->
    <div class="flex flex-col flex-grow overflow-hidden card">
      <div class="flex items-center justify-between p-20 border-b border-solid md:p-30 border-gray-lighter">
        <div class="flex flex-row">
          <h2 class="flex items-center mr-70 text-lg font-bold"><span>Uploads &amp; Messages</span></h2>
          <div class="relative flex w-full pr-10 input pl-30 md:w-300 focus:ring-2 focus:ring-blue-light">
            <mat-icon svgIcon="magnify" class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18">
            </mat-icon>
            <input class="w-full ml-5 font-medium text-sm outline-none appearance-none text-gray-dark" type="text" name="search"
                   aria-label="Search Uploads & Messages"
                   placeholder="Search Uploads & Messages..."
                   [(ngModel)]="searchTerm"
                   (appDebouncedModelChanged)="filterUploads()" />
          </div>
        </div>       
        <div class="flex flex-row gap-x-20">
          <ng-container *appShowIfAll="perm.SubmitMessageFile">
            <button mat-button class="flex items-center justify-center ml-auto button is-primary is-inverted px-10"
                    (click)="openNewMessageModal()">
              <span>Add New</span><span class="ml-10 material-icons mi-18">add</span>
            </button>
          </ng-container>
          <button mat-button class="flex items-center justify-center px-10 button is-primary is-inverted"
                  aria-label="Toggle Filters"
                  (click)="accountsFilter.toggle()">
            <mat-icon svgIcon="filter" class="mr-10 mi-18"></mat-icon>
            <span class="mr-5">Filters</span>
          </button>
        </div>
      </div>
      <mat-sidenav-container autosize="true" class="flex h-full overflow-hidden bg-white">
        <mat-sidenav-content class="flex flex-col flex-grow overflow-hidden h-full">
          <app-files-messages-list class="pb-20 md:pb-30 w-full h-full overflow-auto flex-grow"
                                   [uploads]="filteredUploads"></app-files-messages-list>
        </mat-sidenav-content>
        <mat-sidenav #accountsFilter [opened]="sidenavConfig.IsOpened" [mode]="sidenavConfig.Mode"
                     class="flex flex-col w-200 gap-y-10" position="end">
          <section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
            <label class="text-sm text-gray">Advisors</label>
            <ng-container *ngIf="!isArcAdvisor">
              <mat-checkbox class="flex py-2 text-sm" *ngFor="let advisor of tableFilter.Advisors" [(ngModel)]="advisor.Checked"
                            (ngModelChange)="filterUploads()" [disabled]="isLoading">
                {{advisor.Name}}
              </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="isArcAdvisor">
              <div class="relative flex w-full input pl-30 md:w-250 focus:ring-2 focus:ring-blue-light">
                <mat-icon svgIcon="magnify" class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18">
                </mat-icon>
                <input class="w-full ml-5 font-medium outline-none appearance-none text-gray-dark text-sm" type="text"
                name="search" aria-label="Search advisor" placeholder="Filter..." [(ngModel)]="advisorSearchTerm"
                (appDebouncedModelChanged)="filterAdvisorList()" />
                <mat-icon (click)="advisorSearchTerm=''; filterAdvisorList()" *ngIf="advisorSearchTerm.length > 0" svgIcon="close" class="absolute right-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-15 cursor-pointer">
                </mat-icon>
              </div>   
              <div class="h-360 overflow-y-scroll">                           
                <mat-radio-group class="flex flex-col flex-none gap-y-10" aria-label="Advisors list"
                  [(ngModel)]="selectedRadioAdvisorId" [disabled]="isLoading">
                  <mat-radio-button class="flex py-2 text-sm"
                    *ngFor="let advisorRadio of filteredAdvisors; let index = index" [value]="advisorRadio.Value"
                    [attr.aria-label]="advisorRadio.Name + ' radio button'" [disabled]="isLoading"
                    (click)="radioAdvisorChecked(advisorRadio.Value)">
                    {{advisorRadio.Name}}
                  </mat-radio-button>
                </mat-radio-group>                
              </div>
            </ng-container>
          </section>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </div>
</section>