<section class="flex w-full h-full p-30">
  <div class="flex flex-col w-full mx-auto max-w-1440 gap-y-30">
    <a routerLink="../overview" class="flex items-center p-5 mr-auto text-sm font-bold uppercase gap-x-5">
      <mat-icon svgIcon="arrow-left" class="mi-18"></mat-icon>
      <span>Back</span>
    </a>
    <img class="block w-auto mr-auto h-128" src="assets/images/logos/next-level-logo.png" alt="Next Level Resources">
    <section class="flex flex-col gap-y-30">
      <div class="flex gap-x-30">
        <img src="assets/images/development-center/paul-housey2.png" class="w-auto rounded h-150" alt="Paul Housey, Next Level Coaching Coach">
        <div class="flex flex-col gap-y-10 max-w-960">
          <h2 class="text-xl font-bold">Next Level Coach:</h2>    
          <h2 class="text-xl font-bold">Paul E. Housey, CPA, CFP®, CLU®</h2>
          <h2 class="text-xl font-bold">Co-Founder & President, ISTO Advisors</h2>  
          <p class="text-lg">
            Paul has combined his passion for helping others with teaching; serving others is so central to what Paul does that his firm's name, <span class="font-bold">In Service to Others (ISTO),</span> embodies it. With over 20 years of experience, Paul serves as a world-class coach for advisors enrolled in Alphastar's Next Level Coaching program. His combination of designations, education, and knowledge of best practices uniquely positions him to help advisors build trust and credibility with clients. Paul applies these practices to his teaching by focusing on planning and bringing an awareness of how taxes impact all parts of a financial plan.
          </p>
        </div>
      </div>
      <section class="card" *ngFor="let model of wistiaSections; let index = index">
        <app-development-center-wistia-section class="flex flex-col" [model]="model" [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
      </section>
    </section>
  </div>
</section>